import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote8 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>Integrating Advanced AI Algorithms with Scientific Articles; <br />
            Large Language Models (LLMs) and Beyond
            </b> <br/>
            Dr. Soheyla Amirian<br/>
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            Scientific articles are truly a treasure trove of information, thus extracting meaningful facts and insights from the large columns of such data is critical for scientific progress and innovation. Leveraging advanced artificial intelligence (AI) algorithms is now transforming the way we interact with vast repositories of scientific literature. Large Language Models such as ChatGPT- along with advanced word embedding strategies have revolutionized the way we understand and interact with that text data, enabling tasks like automated summarization, named entity recognition, question answering, and content generation. This talk aims to explore the integration of Large Language Models (LLMs) and word embeddings with large-scale scientific articles, focusing on a case study in healthcare using data from PubMed, unlocking the full potential of scientific articles to advance clinical research. 
            </div>

            <h6 className="par_header">Speaker's Biography:</h6>
            <div className="par">
            Dr. Amirian is currently a faculty fellow at the Institute for Artificial Intelligence and a faculty lecturer at the School of Computing, University of Georgia, where she is also leading the 
            <a href="https://amiielab.github.io/" target="_blank" rel="noopener noreferrer">&nbsp;UGA Applied Machine Intelligence Initiatives & Education (AMIIE) Laboratory</a>
            , working with a multidisciplinary team to build artificial intelligence (AI) and data-driven machine learning (ML) algorithms in different real-world settings, such as public health, imaging informatics, and AI-powered education. Dr. Amirian is the 2023 IEEE Atlanta Section Outstanding Educator awardee, and the 2019 CSCI Outstanding Achievement awardee, and she was named a finalist for the 2020 NCWIT (National Center for Women and Information Technology) Collegiate Award. Of late, she has authored 25+ peer-reviewed publications and has organized several conferences and tutorials on computational intelligence (e.g., ISVC), serving as the Co-Chair of Research Tracks at the World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE) plus the International Conference on Computational Science & Computational Intelligence (CSCI).
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/Amirian.png" height="250px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote8;