import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Scope extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">SCOPE AND LIST OF TOPICS</h4>
            <div className="par">
            The 2025 CSCE International Conference invites paper submissions
            from diverse communities, including researchers from: universities,
            corporations, government agencies, and standardization bodies.
            Papers are sought that address solutions to problems in all areas
            of computational science and computational intelligence.
            <br/> <br/>

            Computational Science is the study of addressing problems that
            are impossible to solve (or difficult to solve) without computers.
            The field is interdisciplinary by nature and includes the use of
            advanced computing capabilities to understand and solve complex
            problems. In short, Computational Science is the science of using
            computers to do science. Computational Intelligence is the study
            of computational methods in ways that exhibit intelligence. These
            methods adapt to changing environments and changing goals.
            <br/> <br/>
            Considering the above broad outline, the International Conference
            on Computational Science and Computational Intelligence (CSCE'25)
            is composed of the following research and technical tracks:
            <br/> <br/>
            <div>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ESCS">Symposium on Computational Science (CSCE-ESCS)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-EEE">Symposium on Computational Intelligence (CSCE-EEE)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-FCS">Symposium on Cyber Warfare, Cyber Defense, & Cyber Security (CSCE-FCS)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ICOMP">Symposium on Signal & Image Processing, Computer Vision & Pattern Recognition (CSCE-ICOMP)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-IKE">Symposium on Smart Cities and Smart Mobility (CSCE-IKE)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-FECS">Symposium on Education (CSCE-FECS) - includes accreditation, such as ABET</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-GCC">Symposium on Health Informatics and Medical Systems (CSCE-GCC)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="https://www.himscsce.org/hims25 " target="_blank" rel="noopener noreferrer">Symposium on Mobile Computing, Wireless Networks, & Security (CSCE-HIMS)</a><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-IPCV">Symposium on Software Engineering (CSCE-IPCV)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-MSV">Symposium on Software Engineering (CSCE-MSV)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-PDPTA">Symposium on Software Engineering (CSCE-PDPTA)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="http://sam.udmercy.edu/sam25/" target="_blank" rel="noopener noreferrer">The 21st International Conference on Security & Management (SAM'25)</a><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-SERP">Symposium on Software Engineering (CSCE-SERP)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="https://icdata.org" target="_blank" rel="noopener noreferrer">Symposium on Internet of Things & Internet of Everything (CSCE-ICDATA)</a><br/>
                <Icon icon='link' size="1x"/> <a href="https://qmexico.org/ICEQT2025/" target="_blank" rel="noopener noreferrer">The International Conference on Emergent Quantum Technologies (ICEQT'25)</a> <br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-BIOCOMP">Symposium on Big Data and Data Science (CSCE-BIOCOMP)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ACC">Symposium on Artificial Intelligence (CSCE-ACC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ICAI">Symposium on Social Network Analysis, Social Media, & Mining (CSCE-ICAI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-CSC">Symposium on Cloud Computing and Data Centers (CSCE-CSC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-BIOENG">Symposium on Computational Biology (CSCE-BIOENG)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ICWN">Symposium on Parallel & Distributed Computing (CSCE-ICWN)</Link><br/>
              </div>
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Scope;