import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote3 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>Explaining the Design of the Quantum Fourier Transform</b> <br/>
            Professor Leon Deligiannidis, Ph.D. <br/>
            Interim Dean and Professor <br />
            School of Computing and Data Science, Wentworth Institute of Technology, Massachusetts, USA<br /><br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            The Discrete Fourier Transform (DFT) is one of the most practical mathematical tools that has been developed and
is used in engineering, sciences and beyond. It converts a finite sequence of samples from the time/space domain
into the frequency domain, and vice versa. Many operations and analyses are simpler in the frequency domain
where after the appropriate operation (or filtering), the signal is converted back to the spatial domain. The Quantum
Fourier Transform (QFT) is the quantum equivalent of the classical DFT. Instead of operating on classical signals, it
operates on quantum states. Many important quantum algorithms utilize the QFT, or the inverse QFT, at their last
step to extract the periodicity from their input quantum states. Some of these algorithms are Shor’s, Quantum Phase
Estimation (QPE), Quantum Counting and numerous other algorithms. In this work, we attempt to present QFT in a
way that undergraduate students can understand the design of the algorithm and the reasoning of the
implementation.
            {/* <br /><br />
            <b>Code Repository: </b> <a href="https://github.com/Leon-WIT/Grover" target="_blank" rel="noopener noreferrer">https://github.com/Leon-WIT/Grover</a>  */}
            </div>

            <h6 className="par_header">Speaker's Biography:</h6>
            <div className="par">
            Dr. Leonidas Deligiannidis is a Professor of Computer Science at Wentworth Institute of Technology in Boston,
MA. His foundation in Computer Science was first established when he earned his B.S. from Northeastern
University College of Computer Science. He then went on to obtain two advanced degrees in the field from Tufts
University. He has been active in the field since then, investigating subjects like Security Surveillance, Brain
Computer Interfaces, Business-oriented visualizations, and STEM Education. He has also served in industrial
positions outside the scope of his academic work, using his acumen for several companies. His industry experience
includes both entrepreneur startups in healthcare and high-tech networking companies. He has received multiple
awards for his efforts, including multiple Achievement Awards for his contributions to Imaging Science by the
World Congress in Computer Science, Computer Engineering, and Applied Computing, IEEE best paper awards, as
well as a University Presidential award for his scholarly achievements. In addition, he has served as a referee for
many journals in computer science and is a member of the program and organizing committees for notable
conferences in his field. Dr. Deligiannidis teaches courses such as Security, Operating Systems, Quantum
Computing for Security, System/Network Administration, Senior Design.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/leon.jpg" height="300px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote3;