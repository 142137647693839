import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Authors extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Instructions for Authors of Accepted Papers</h4>
            <div className="par">
               <b>Congratulations</b> on having a paper accepted for publication in the CSCE'25 conference proceedings. This page contains useful information for authors who will be publishing their papers in the proceedings  and presenting their papers at the conference. 
            </div>

            <h6 className="par_header">Conference Registration</h6>
            <div className="par">
            In order to have their paper(s) published in conference proceedings, <b>at least one author for each accepted paper is required to register before the announced deadline</b>. If more than one author plan to attend the conference, then each author is required to register. 
            &nbsp;<Link to="/csce2025/registration">Click Here&nbsp;</Link>
            to go to the conference registration page for more details.
            </div>

            <h6 className="par_header">Hotel Reservation</h6>
            <div className="par">
            To obtain information about the hotel accommodations and to book your hotel room <Link to="/csce2025/venue">Click Here</Link>.  Please note that <b>ROOMS MUST BE RESERVED BY THE<Link to="/csce2025/deadlines">&nbsp;ANNOUNCED DEADLINE</Link>.</b>
            </div>

            <h6 className="par_header">Typesetting Instructions for Preparation of Final Camera-ready Papers</h6>
            <div className="par">
            Please note that the instructions for the preparation of Camera-ready Papers are intended to give consistency of style and format. Failure to comply with these instructions may result in your paper being rejected and cause a delay in its publication.  
            <b> The link to the instructions has been emailed to the authors of the accepted papers.</b> 
            </div>

            <h6 className="par_header">Submission of Final Camera-ready Papers</h6>
            <div className="par">
            Final Camera-ready Papers must be uploaded to the publication website by the <Link to="/csce2025/deadlines">announced deadline</Link>. The link to the web site has been emailed to the authors of the accepted papers. Please note that in order for your paper(s) to be included in the conference proceedings, we must receive the Registration fees before the announced deadline.
            </div>

            {/* <h6 className="par_header">Paper Categories and Presentation Format</h6>
            <div className="par">
            The Conference Paper Selection Committee has approved the following categories of accepted papers:
            <br/><br/>
            <b>- Full/Regular Research Papers:</b> Formal Presentation and 6-Page Publication in the Proceedings. <br/>
            <b>- Short Research Papers:</b> Formal Presentation and 4-Page Publication in the Proceedings. <br/>
            <b>- Poster Papers:</b> Presentation in Discussion Sessions and 2-Page Publication in the Proceedings. <br/>
            <br/>
            Authors of accepted papers must follow the instructions for the category in which their paper(s) has been accepted. 
            <br/>
            <Link to="/csce2025/paper_categories">Read More</Link>
            </div> */}

            <h6 className="par_header">Invitation Letters for US Visa</h6>
            <div className="par">
            To request an "Invitation Letter for US Visa" for participation in CSCE'25, please follow the instructions that appear on a particular website; the link to this particular website has been emailed to the authors of the accepted papers.
            <br/> <br/>
            <b>Visa Information for International Participants Traveling to the United States</b>
            <br/> <br/>
            We advise scientists traveling to the United States to apply for a visa as early as possible. Below are helpful links to various websites with general visa information:
            <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="http://usembassy.state.gov/">Websites of U.S. Embassies and Consulates Around the World</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="http://sites.nationalacademies.org/PGA/biso/visas/">International Visitor's Office</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="http://travel.state.gov/content/visas/en/visit/visitor.html">B-1 Visa (How to apply)</a><br/>

            <br/>
            For additional information regarding US visa, please contact your <a target="_blank" href="http://usembassy.state.gov/">local US embassy</a>.
            </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Authors;