import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsIKE extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">IKE'25 - The 24th Int'l Conf on Information & Knowledge Engineering</h4>
            <div className="par">
            IKE is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2025 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'25).
<br/><br/>
We anticipate having between 1,000 and 1,500 participants in the Congress. The congress includes 21 major tracks composed of: 112 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 21-24, 2025. Last year, the Congress had attracted speakers/authors and participants affiliated with over 148 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings (Research Books) and indexed in major Science Citation Indexation databases (including: Scopus; DBLP; EI Engineering Index; Compendex; Inspec databases; Springer Links; Google Scholar; CP Citation Index, CPCI; part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.)
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            <b>Past Publications of Proceedings/Books can be found at:</b><br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>  <br />
            <a href="https://american-cse.org/csce2025/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/special_issues</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings</a><br />  
            <br /> 
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            - Information Retrieval Systems <br/>
            - Knowledge Management and Cyber-Learning<br/>
            - Database Engineering and Systems<br/>
            - Data and Knowledge Processing<br/>
            - Databanks: Issues, Methods, and Standards<br/>
            - Data Warehousing and Datacenters<br/>
            - Health Information Systems<br/>
            - Data Security and Privacy Issues<br/>
            - Information Reliability and Security<br/>
            - Information and Knowledge Structures<br/>
            - Knowledge Life Cycle<br/>
            - Knowledge and Information Extraction and Discovery Techniques<br/>
            - Knowledge Classification Tools<br/>
            - Knowledge and Information Management Techniques<br/>
            - Knowledge Extraction from Images<br/>
            - Knowledge Representation and Acquisition<br/>
            - Knowledge Re-engineering<br/>
            - Large-scale Information Processing Methods<br/>
            - Intelligent Knowledge-based Systems<br/>
            - Re-usability of Software/Knowledge/Informationv
            - Formal and Visual Specification Languages<br/>
            - Decision Support and Expert Systems<br/>
            - e-Libraries (Digital Libraries) + e-Publishing<br/>
            - Ontology: Engineering, Sharing and Reuse, Matching and Alignment<br/>
            - Digital Typography<br/>
            - Agent-based Techniques and Systems<br/>
            - Workflow Management<br/>
            - Business Intelligence<br/>
            - Large-scale Information Processing Methods and Systems<br/>
            - Content Management<br/>
            - Data and Knowledge Fusion<br/>
            - Dataweb Models and Systems<br/>
            - Global Contextual Processing and Management Implementation<br/>
            - Data/Information/Knowledge Models<br/>
            - Managing Copyright Laws<br/>
            - Interoperability Issues<br/>
            - Transaction Systems<br/>
            - Search Methods<br/>
            - Ontologies and Semantics<br/>
            - Object-oriented Modeling and Systems<br/>
            - Case-based Reasoning<br/>
            - Digital Watermarking<br/>
            - Classical Aspects of Information Theory<br/>
            - Coding Theory<br/>
            - Quantum Information Theory<br/>
            - Applications (e-Commerce, Multimedia, Business, Banking, ...)<br/>
            - Emerging Technologies and Related Issues<br/>
            - Natural Language Processing<br/>
            - Information Integration<br/>
            - Multi-cultural Information Systems<br/>
            - Domain Analysis and Modeling<br/>
            - Metamodelling<br/>
            - Education and Training Issues<br/>
            - Knowledge Mining<br/>
            <br/>
            <b>Information and Knowledge Engineering in the Context of Big Data:</b> <br/>
            - Massively Parallel Processing (MPP) Databases <br/>
            - Information Mining Grids<br/>
            - Distributed Databases<br/>
            - Cluster Analysis<br/>
            - Crowdsourcing<br/>
            - Data Fusion and Integration<br/>
            - Neural Networks<br/>
            - Pattern Recognition<br/>
            - Data Anomaly Detection<br/>
            - Supervised and Unsupervised Learning<br/>
            - Time Series Analysis and Visualisation<br/>
            - Search-based Applications<br/>
            </div>            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsIKE;