import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Home extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
               
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">The 2025 World Congress in Computer Science, Computer Engineering, & Applied Computing (CSCE'25)</h4>
            <div className="par">
              Welcome to the 2025 World Congress in Computer Science, Computer Engineering, and Applied Computing website. 
              We anticipate having between 1,000 and 1,500 participants in the Congress. 
              The congress includes <b><Link to="/csce2025/conferences">21 major tracks</Link></b> composed of: 112 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 21-24, 2025.
              This year's Congress will be held in person (face-to-face); however, online presentation options will also be available for authors and speakers who are unable to attend physically. We expect that the vast majority of speakers will attend in person, with most presentations being delivered face-to-face.
              <br/><br/>
              The Congress is among the top five largest annual gathering of researchers in computer science, computer engineering, data science, artificial intelligence, STEM, and applied computing. We anticipate to have attendees from about 75 countries and territories.
              {/* <a target="_blank" href=""> Read more </a> */}
            </div>

            <h6 className="par_header">Conference Objectives and Demography</h6>
            <div className="par">
            Last year, the Congress had attracted speakers/authors and participants affiliated with over 148 different universities (including many from the top 50 ranked institutions). In the past, participants have included affiliates from:
            <br /><br />
            MAJOR IT CORPORATIONS, INCLUDING: <br />
Huawei Technologies (China); OpenAI (USA); Apple Inc. (USA); Tesla, Inc.  (USA); Microsoft Corporation (USA); Alphabet Inc. (Google - USA); Google DeepMind; Meta Platforms (Facebook - USA); Tencent Holdings (IT - China); IBM (International Business Machines - USA, India, UK); Samsung Electronics (South Korea); Oracle Corporation (USA); Intel Corp (USA); SAP SE (Germany); Amazon (USA); Salesforce (USA); Alibaba Group (China); Dell Technologies (USA); HP Inc. (Hewlett-Packard - USA); ByteDance (TikTok and Douyin - China); Yahoo (USA); Ebay (USA); NTT (Japan); BTS Software Solutions (USA); NetApp, Inc. (Data Storage - USA); METRON (France); Wipro Technologies (India); VNIIEF (Russia); Synchrone Technologies (France); Infosys (India); Cisco Systems (USA); NVIDIA (USA); Bosch Global Software Technologies (Germany); Tata Consultancy Services (USA); SAP Software (Germany); Capgemini (France); Cognizant Technology Solutions (USA); Adobe Inc. (USA); Foxconn (Taiwan); AT&T (USA); BCL Technologies (USA); Huawei Technologies Co., Ltd. (China); Sony (Japan); Panasonic (Japan); Lenovo (China); LG Electronics (South Korea); Naver Corp (South Korea); Baidu (China); China Mobile (China); and others.
<br /><br />
MAJOR CORPORATIONS (MULTI-SECTORS), INCLUDING: <br />
JPMorgan Chase & Co. (USA); Saudi Aramco (Saudi Arabia); Raytheon (RTX Corp - USA); Visa Inc. (USA); Secured Smart Electric Vehicle (South Korea); Johnson & Johnson (USA); Toyota Motor Corporation (Japan); Procter & Gamble (P&G - USA); Roche Holding AG (Pharmaceuticals and Biotechnology - Switzerland); Walmart (USA); Chevron Corporation (USA); Hyundai Motor Company (South Korea); Exxon Mobil (USA); PetroChina (China); GlaxoSmithKline (UK); CERENA Petroleum Group (Portugal); Aerospace Electronic Technology (China); Max Planck (Germany); Bank of China (China); ENGIE (France); Forschungszentrum Juelich (Germany); China National Petroleum; BASF (Germany); Bayer (Germany); Johnson & Johnson (USA); HSBC (UK); ICBC (Industrial and Commercial Bank of China - China); Airbus (France); Berkshire Hathaway (USA); Boeing (USA); Goldman Sachs (USA); Deutsche Bank (Germany); Sinopec Group (China); United Health (USA); Volkswagen (Germany); China Construction Bank (China); Barclays Bank (UK); BP (UK); Royal Dutch Shell (Netherlands); Daimler (Germany); China Railway Engineering Corp. (China); Cigna (USA); Trafigura (Singapore); Glencore (Switzerland); Ford (USA); AXA (France); Taiwan Semiconductor (Taiwan); Accenture (Ireland); Siemens (Germany); Philips (Netherlands); Ericsson (Sweden); General Electric (USA); BAE Systems (USA); Hitachi (Japan); Fraunhofer IKTS (Germany); State Grid Corporation (China); Foxconn (Taiwan); and others.
<br /><br />
GOVERNMENT RESEARCH AGENCIES AND NATIONAL LABORATORIES, INCLUDING: <br />
National Science Foundation (NSF), National Institutes of Health (NIH), US Air Force (multiple research labs), National Security Agency (NSA), Pacific Northwest National Lab (PNNL), National Aeronautics & Space Administration (NASA), Lawrence Berkeley National Lab (LLNL), Lawrence Livermore National Lab, Los Alamos National Lab (LANL), Argonne National Lab, Cold Spring Harbor Lab, US National Cancer Institute, Swedish Defence Research Agency, US National Institute of Standards and Technology (NIST), Oak Ridge National Lab, U.S. Geological Survey, US National Library of Medicine, SPAWAR Systems Center Pacific, CERN (Switzerland), China NSF, Russian Academy of Sciences, Sandia National Lab, Savannah River National Lab, US Navy, Ames Lab, Hasso Plattner Institute, US Army, Korea Internet & Security Agency, DESY (Germany), LNGS (Italy), Suez Canal Research Center, Okinawa Bureau (Japan), Canadian Medical Protective Association, Osong Medical Innovation Foundation (Korea), Royal Observatory Edinburgh (UK), Jet Propulsion Laboratory (JPL), Gran Sasso National Lab (Italy), National Institute of Informatics (Japan), Frederick National Lab for Cancer Research, Idaho National Laboratory, ...; and three Venture Capitalists and twelve Intellectual Property attorneys, and others.
<br /><br />
Based on 1995 through 2024 participation data: <br />
In the past, about 45% of the attendees have been from outside USA; from 86
nations. 56% of registrants have been from academia, 32% from industry; 11%
from government agencies and research labs; and 1% unknown. The conference
has welcomed participants, speakers, and authors from over 572 institutions
and universities, including many from the top 50 ranked institutions worldwide.
<br /><br />
To get a feeling about the conferences' atmosphere, see some delegates photos available at (over 2,000 photos): <br />
<a href="https://american-cse.org/csce2025/gallery" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/gallery</a> 

            <br/> <br/>
            <b>An important mission of the Congress</b> is to Provide a unique platform for a diverse community of constituents composed of scholars, researchers, developers, educators, and practitioners. The Congress makes concerted effort to reach out to participants affiliated with diverse entities (such as: universities, institutions, corporations, government agencies, and research centers/labs) from all over the world. The congress also attempts to connect participants from institutions that have teaching as their main mission with those who are affiliated with institutions that have research as their main mission. The congress uses a quota system to achieve its institution and geography diversity objectives.
            <br/><br/>
            To get a feeling about the conferences' atmosphere, see:
            <br/>      
            <Icon icon='link' size="1x"/> <Link to="/csce2025/gallery">Photo Gallery</Link><br/>
            </div>

            <h6 className="par_header">INVITATION</h6>
            <div className="par">
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings (Research Books) and indexed in major Science Citation Indexation databases (including: Scopus; DBLP; EI Engineering Index; Compendex; Inspec databases; Springer Links; Google Scholar; CP Citation Index, CPCI; part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.)
            <br /><br />
            This year's Congress will be held in person (face-to-face); however, online presentation options will also be available for authors and speakers who are unable to attend physically. We expect that the vast majority of speakers will attend in person, with most presentations being delivered face-to-face.
            <br/> 
            
            {/* <br />
            <b>CSCE 2023 Proceedings (Last Year’s Xplore Indexation) can be found at:</b>
            <br /> */}
            {/* <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> */}
            
             
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            <b>Past Publications of Proceedings/Books can be found at:</b><br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>  <br />
            <a href="https://american-cse.org/csce2025/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/special_issues</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings</a><br />  
            <br /> 
            Paper submission portal:  <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            {/* <Icon icon='link' size="1x"/> <a target="_blank" href="https://www.springer.com/series/11769">https://www.springer.com/series/11769</a><br/> */}
            <br/>

            {/* <b>Indexation:</b> Subject to Springer science indexation which includes: <a href="http://link.springer.com/" target="_blank" rel="noopener noreferrer">online Springer Link</a>, 
            <a href="http://www.info.scopus.com" target="_blank" rel="noopener noreferrer"> Scopus</a>, 
            SCI Compendex, <a href="http://www.ei.org" target="_blank" rel="noopener noreferrer">EI Compendex</a>, 
            EMBASE, Web of Science, Inspec, ACM digital library, Google Scholar, EBSCO, and others.
            <br/>
            <div style={{textAlign: "center"}}>
            <img src="/static/sp.png" alt=""/>
            </div> */}
            {/* <br/> */}
            {/* Prospective authors are invited to submit their papers by uploading them to the evaluation web site at:  <br/>
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a> 
            <br/><br/>
            See the web link below for a small subset of publications based on papers accepted in the
             CSCE proceedings: many of these books and journal special issues have already received the top 25% downloads in their 
             respective fields and/or identified as "Highly Accessed" by publishers and/or science citation index trackers.
            <br/>
            <Icon icon='link' size="1x"/> <Link to="/csce2025/special_issues">Special Issues</Link><br/>
            <br/>
            Refer to the link below for some information on citation record: <br/>
            <Icon icon='link' size="1x"/> <Link to="/csce2025/publisher">Publication</Link><br/> */}
            </div>
            
            
            <h6 className="par_header">Past Distinguished Speakers of the Congress</h6>
            <div className="par">
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. David Patterson</div>
                      Fellow: IEEE, ACM, AAAS & Member, National Academy of Engineering; Shared with John Hennessy, the IEEE John von Neumann Medal; University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to RISC processor design)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/patterson.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Ian Foster</div>
                      Fellow: ACM, AAAS, BCS; Distinguished Fellow, Argonne National Lab.; Distinguished Professor, University of Chicago
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Introduced Grid Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/foster.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>The Late Prof. Lotfi A. Zadeh</div>
                      Fellow: IEEE, AAAS, ACM, AAAI, IFSA;
                      Member: National Academy of Engineering
                      University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Fuzzy Logic)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/zadeh.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. H. J. Siegel</div>
                      Fellow: IEEE & ACM; Distinguished Professor
                      Colorado State University (formerly at Purdue)
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to Heterogeneous Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/siegel.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>The Late Prof. John Holland</div>
                      University of Michigan
                      Ann Arbor, Michigan
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Genetic Algorithms)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/holland.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Ruzena Bajcsy</div>
                      Member: National Academy of Eng., National Academy of Science; Fellow: IEEE, ACM, AAAI; Director, CITRIS
                      University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to AI, HCI & vision)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bajcsy.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Firouz Naderi</div>
                      Former Director, Solar System Exploration, NASA's JPL;
                      Former NASA's Program Manager for Mars Explortion
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Contributed to some of America's most iconic robotic space missions)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/naderi.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. John R. Koza</div>
                      Former Consulting Prof., Stanford University
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Genetic Programming)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Koza.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. David Lorge Parnas</div>
                      Fellow: IEEE, ACM, RSC, CAE, GI; MRIA
                      McMaster University, Canada
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer of Software Engineering)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/parnas-photo_02.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. John Gustafson</div>
                      Recipient of Inaugural Gordon Bell Prize;
                      CTO, Ceranovo, Inc.
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Inventor of Gustafson's Law)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Gustafson.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Anousheh Ansari</div>
                      Chairwoman, Prodea Systems; Space Ambassador;
                      <br></br>
                      <div style={{color: '#4b6584'}}>(First Female Space Tourist)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/anousheh.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>

              <br/>
              <div style={{width: '100%', alignContent: "center", textAlign: "center", fontWeight: "bold"}}>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/past_speakers">See more</Link> <Icon icon='link' size="1x"/>
              </div>
           
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Home;