import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Accordion, Card  } from 'react-bootstrap';

class Approved_Sesss2 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Special Session on Emerging Trends & Technologies in Computing & Management (ETTICM)</h4>
            <div className="par">
            <b>In association with <a href="https://www.american-cse.org/csce2025/conferences-EEE" target="_blank" rel="noopener noreferrer">EEE'25 - The 21st Int'l Conf on e-Learning, e-Business, Enterprise Information Systems, and e-Government</a></b>            
                <h6 className="par_header">Track Chairs</h6>
                <div className="par">
                  <b>Hardeep Singh</b> <br/>
                  IK Gujral Punjab Technical University, Kapurthala (Punjab) India.<br/>
                  E-mail: geniussodhi@gmail.com<br/>
                  Contact: +91-78891-45914<br/> <br/>
                  <b>Bikram Pal Singh</b> <br/>
                  Global Group of Institutes, Amritsar (Punjab) India<br/>
                  Email: bs542358@gmail.com<br/>
                  Contact: +91-98140-51061<br/>
                </div>
                <h6 className="par_header">Aim</h6>
                <div className="par">
                  The main aim of this special session is to discuss about the Emerging Trends and Technologies in
                  Computing and Management. This session aims to bring together leading academic scientists, researchers and
                  research scholars to exchange and share their experiences and research results about all Emerging Trends and
                  Technologies in Computing and Management. This will provide a forum for the interaction and integration in the
                  related disciplines
                </div>
                <h6 className="par_header">Areas of Coverage (sub-themes)</h6>
                <div className="par">
                The scope of this special session is related to the below given topics but not limited to these. The researchers/authors
                are open to send UNPUBLISHED GOOD QUALITY RESEARCH PAPERS apart from the below topics related
                to the session area: <br/> <br/>

                - Big Data, Cloud Computing, Internet of Things, Information Security, Electronic CAD Tools, Smart
                Vehicles, VLSI Technology Trends, Configurable Computing, Optical Computing, Molecular
                Nanotechnology, Quantum Computing, IT & Security Considerations, Sustainable Computing, Green
                Computing <br/>
                - Cyber Insurance, Cyber Security, Business Intelligence & IT Security, Ethical Hacking, Alignment of
                Business and IT Security Efforts, Security Awareness, Big Data Analysis, Data Mining, Natural Language
                Programming, Biometrics Security, Cloud Security and Privacy, Cryptography, Database Security and
                Privacy, Distributed Systems Security and Privacy. <br/>
                - Emerging Trends & Technologies in E-Governance / M-Governance/E-business/E- Commerce/ETraining/
                MCommerce, Mobile Computing, Cloud computing, Emerging Trends of IT in Tourism Industry,
                Impact of IT in Tourism Development, IT and Globalization of Tourism. <br/>
                - Emerging Trends & Upcoming Technologies of IT in Training & Development, Role of IT in Training &
                Development, Role of IT and HR Manager in Development of Organization, IT Professionals and Training
                Staff Coordination, Security Training and Its International perspective, ICT in Training & Development-
                Challenges and Issues, , Job Satisfaction, IT & Job Evaluation, Emerging Trends & Upcoming
                Technologies of IT in Human Resource Management, Marketing Management, Finance Management,
                International HRM. <br/>
                - Power Engineering and Power Systems, Electromagnetic Compatibility, Energy Conversion, High Voltage
                Engineering and Insulation, Power Delivery, Power Electronics, Illumination,
                Control Theory and Applications, Adaptive and Learning Control System, Fuzzy and Neural Control,
                Mechatronics, Manufacturing Control Systems and Applications, Process Control Systems, Robotics and
                Automation <br/>
                - Communication and Signal Processing, Audio-Visual Recording and Storage, Image/Video Coding, 3D
                Video Representations and Compression, 3D Graphics and Animation Compression, HDTV and DVBT
                systems, Video Adaptation, Wireless Video Broadcasting and Communication, Visual Surveillance, Video
                Restoration, MultiCamera Video Analysis, Motion Analysis, Face and Gesture Processing, Video
                Synthesis, Architectures for Image/Video Processing and Communication. <br/>
                - Nanotechnology and Upcoming Careers for Engineers, Communication Skills and Engineering Career,
                Stress Management, Time Management, English Language and Growth Opportunities in Career, Soft Skills
                and Career, Role of Social Sites in building Professional Relationships, Fluent English Speaking and
                International Careers, Role of English Speaking in Upliftment of Career.

                <br/> <hr/>
                Submit your original Full length paper for presentation and publication or Abstract on <br/>
                <b>geniussodhi@gmail.com / optimumsodhi@hotmail.com</b>  <br/> <br/>
                <b>Special Note: All submissions must be plagiarism free</b> 
                </div>

                <h6 className="par_header">Publication</h6>
                <div className="par">
                ALL ACCEPTED PAPERS will be published in the corresponding proceedings by Publisher: Springer Nature - Book
                Series: Transactions on Computational Science & Computational Intelligence <br/>
                <a href="https://www.springer.com/series/11769" target="_blank" rel="noopener noreferrer">https://www.springer.com/series/11769</a> <br/>
                Indexation: Subject to Springer science indexation which includes: online Springer Link,Scopus, SCI Compendex, EI
                Compendex, EMBASE, Web of Science, Inspec, ACM digital library, Google Scholar, EBSCO, and others.
                </div>

                <h6 className="par_header">Session Chairperson(s) Biography</h6>
                <div className="par"> 
                <b>Hardeep Singh</b> (Senior Academician, Researcher, Author, Orator, Motivational Speaker and Life Coach) having 19
                years of total experience (14 years of Academic with Engineering College & 05 years Corporate) is life member of
                various bodies like IAER, CSI, IACSIT, IEDRC, IFRSA, ISTD. He has been honored as a “Patron Member” by
                World Academic-Industry Research Collaboration Organization (WAIRCO) for the period of Life time. Currently he
                is affiliated with IKG-PTU, Kapurthala (Punjab) India. He has been esteemed to address various conferences as Key-
                Note Speaker. He has attended 41 International Conferences; 36 National Conferences, 11 UGC Seminars and 100+
                Webinars/Workshops/FDPs/EDPs. He has presented 100+ Research Papers/ Articles. He has published 100+ Research
                Papers/Articles in Conference Proceedings and journals of national/ international fame under the tag of publishers of
                well repute like Elsevier, Scopus, IEEE, and Springer etc. He has guided 100+ projects to the students of BTech and
                MBA. He has also guided MTech candidates for writing and publication of Research Papers. He has authored four
                books with an International Publisher. His latest book (co-authored by Prof. J.S. Jogi & Dr. DP Kothari-a well-known
                Scientist & Educationist) “Written & Oral Technical Communication Skills for Engineers/Scientists” is available in 8
                foreign languages. He has received numerous Awards-Republic Day Achievers Award 2018, Leadership Excellence
                Award 2019, Living Legend Award, GRABS Best Social Scientist Award-2020 and Dr. Radhakrishnan Award from
                prestigious institutions of international repute. Currently he has been invited as Conference Chair for ICEG
                2025 scheduled to take place from 13-14 August 2025 at Hong Kong. <br/><br/>
                <b>Bikram Pal Singh</b> Currently affiliated with Global group of Institutes Amritsar, Punjab he is Life Member of many
                national and international bodies. He has received several awards. He has attended several International/National
                Conferences and has 40 Research Papers published with various Journals of national as well international repute. He
                has also been honored as Key Note Speaker, Session Chair, Technical Committee Member and Reviewer at various
                International and National Conferences. He is member of editorial board of various journals.
                </div>

                <h6 className="par_header">Contact Details</h6>
                <div className="par"> 
                 <b>E-Mail:</b> geniussodhi@gmail.com/optimumsodhi@hotmail.com <br/>
                 <b>Mobile:</b> +91-94176-60484 / +91-98140-51061
                </div>

            </div>
          </div>
          

        </div>
      </div>
    );
  }
}

export default Approved_Sesss2;