import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Button } from 'react-bootstrap';
import { push } from 'react-router-redux'
import {connect} from 'react-redux';

class Reg_start extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Online Registration</h4>
            <div className="par">            
                You are about to begin a simple three-step registration process. You will be asked to provide the following:
                <br/><br/>
                <b>Step 1 - </b> Attendee Information: Full Name, Affiliation, Email address ..... of the person who would be attending the conference.
                <br/><br/>
                <b>Step 2 - </b> Conference Information: Name of the conference and status of the attendee (Student or Non-student). If registering as an Author, ID number(s) and Title(s) of the Paper(s).
                <br/><br/>
                <b>Step 3 - </b> Payment Information: Credit Card information to pay the fees.
                <br/><br/>
                Please note that a copy of the Payment Receipt will be emailed to you, once the registration is completed.
                <br/>
                Direct all questions or problems related to use of the Online Registration System to Farzan Shenavarmasouleh by Email: farzan.shenavarmasouleh@gmail.com

                <br/><br/>
                    <div style={{"text-align": "center"}}>
                        <Button style={{margin: "0 auto"}} onClick={()=>{
                            this.props.push("/csce2025/registration_form")
                        }}>
                                Start
                        </Button>
                    </div>
            </div>

            <hr/>

            <div className="par">      
              If you want to add <b>additional papers</b>, click below:

              <br/><br/>
              <div style={{"text-align": "center"}}>
                  <Button style={{margin: "0 auto", "backgroundColor": "orange", "borderColor": "white"}} onClick={()=>{
                      this.props.push("/csce2025/registration_form_add")
                  }}>
                          Start
                  </Button>
              </div>
            
            </div>

          </div>
          

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps())(Reg_start);