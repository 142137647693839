import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Accordion, Card  } from 'react-bootstrap';

class Approved_Sesss3 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Special Session on Agile IT Service Practices for the Cloud – a Customer Perspective</h4>
            <div className="par">
            <b>In association with <a href="https://www.american-cse.org/csce2025/conferences-EEE" target="_blank" rel="noopener noreferrer">EEE'25 - The 21st Int'l Conf on e-Learning, e-Business, Enterprise Information Systems, and e-Government</a></b>            
                <br/> <br/>
                This year's Congress will be a hybrid event that combines a "live" in-person event with a "virtual" online component. 
                Please refer to the following for presentation options: <a href="https://www.american-cse.org/csce2025/presentation_modes" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csce2025/presentation_modes</a>
                <br/>
                <h6 className="par_header">CHAIRS OF SESSION/TRACK:</h6>
                <div className="par">
                    <b>Dr. Manuel Mora</b> - Autonomous University of Aguascalientes, Mexico <br/>
                    <b>Dr. Jorge Marx Gomez</b> - University of Oldenburg, Germany <br/>
                    <b>Dr. Raul Valverde</b> - Concordia University, Canada <br/>
                </div>

                <h6 className="par_header">PUBLICATION AND INDEXATION</h6>
                <div className="par">
                All accepted papers will be published by Springer Nature. Publisher: Springer Nature - Book Series: 
                Transactions on Computational Science & Computational Intelligence 
                <a href="https://www.springer.com/series/11769" target="_blank" rel="noopener noreferrer">https://www.springer.com/series/11769</a> 
                <br/> <br/>
                The books will be widely disseminated (to over 1,000 libraries) and will be accessible by tens of thousands of individuals. It should be noted that publication of papers in research books would permit each paper to be quite comprehensive (if need be) and so the publication value would be comparable to journal publications and thus would be listed as such in the list of research contributions by most individuals. Indexation systems include: online Springer Link, Scopus, SCI Compendex, EI Compendex, EMBASE, Web of Science, Inspec, ACM digital library, Google Scholar, EBSCO, and others.
                </div>

                <h6 className="par_header">SPECIAL ISSUE PUBLICATION</h6>
                <div className="par">
                Because this special session will accept only Full/Regular 10-page Research papers, most of the accepted papers will be invited (i.e. their extended versions) for a forthcoming Special Issue to be published in the prestigious Journal of Supercomputing from Springer (indexed by the major science citation indexation systems such as JCR, Scopus, and Scimago).
                </div>

                <h6 className="par_header">SUBMISSION TYPES AND GUIDELINES</h6>
                <div className="par">
                This special session will only accept Full/Regular 10-Page Research Papers. 10-Page Research Papers should provide detail original research contributions. They must report new research results that represent a contribution to the field; sufficient details and support for the results and conclusions should also be provided. The work presented in regular papers is expected to be at a stage of maturity that with some additional work can be published as journal papers.
                <br/> <br/>
                These submission guidelines are comparable to the standard template for IEEE typesetting format. <br/>
                - Papers must be prepared in two-column format. <br/>
                - Word Template at: <a href="http://american-cse.org/download/csce_word_template.doc" target="_blank" rel="noopener noreferrer">http://american-cse.org/download/csce_word_template.doc</a> <br/>
                - The first page of the paper should include the following elements: <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; - Title of the paper <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; - Name, affiliation, postal address, and email address of each author (identify the name of the Contact Author) <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; - Abstract (between 100 and 120 words) <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; - A maximum of 5 topical keywords that would best represent the work described in the paper <br/>
                &nbsp;&nbsp;&nbsp;&nbsp; - The actual text of the paper can start from the first page (space permitting) <br/>

                <br/> <br/>
                <b>All 10-Page Research Papers must be submitted to the lead Session Chair (Dr. Manuel Mora, email: jose.mora@edu.uaa.mx).</b>
                </div>

                <h6 className="par_header">REVIEW PROCESS</h6>
                <div className="par">
                Authors are asked to conform to the common CODE OF ETHICS FOR AUTHORS 
                (The document for the Code of Ethics is available on the submission website at: 
                <a href="https://american-cse.org/download/code-of-ethics.docx" target="_blank" rel="noopener noreferrer"> https://american-cse.org/download/code-of-ethics.docx</a>
                ). 
                Each paper will be peer-reviewed by two experts in the field for its content soundness, practical relevance, methodological correctness, and readability. In cases of contradictory recommendations, session chairs will make the final decision (accept/reject).
                </div>

                <h6 className="par_header">IMPORTANT DEADLINES</h6>
                <div className="par">
                <b>extended to June 8, 2025:</b> <br/>
                Submission of the 10-page research paper <br/><br/>
                <b>updated to June 28, 2025:</b> <br/>
                Notification of the review result (+/- two days) <br/><br/>
                <b>June 30, 2025:</b> <br/>
                Submission of the final version of the accepted papers, the copyright forms, and the Registration confirmation <br/><br/>
                <b>July 21-24, 2025:</b> <br/>
                The 2025 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'25: USA); Including all affiliated federated/joint conferences -  (https://www.american-cse.org/csce2025/) <br/><br/>
                </div>

                <h6 className="par_header">SPECIAL SESSION DESCRIPTION</h6>
                <div className="par">
                Cloud Data Centers have become the ICT infrastructure backbone that permits the delivery of thousands of IT services every day in the modern business world (Wu and Buyya, 2015; Sharma et al., 2016; Senyo 2018; Wu et al., 2019; Bounagui et al., 2019). However, outages and breakdowns caused by IT management or IT engineering issues in Cloud Data Centers are frequently reported (Totaluptime, 2020; CRN, 2020). <br/> <br/>
                To anticipate and cope with IT service disruptions, in the last decade, practically most of the large- and medium-sized global business corporations using on-premise Data Centers have implemented some of the several available plan-driven (i.e. rigor-oriented) IT Service Management (ITSM) frameworks and standards, such as ITIL v2011 (itSMF UK & Agutter, 2012), CMMI-SVC v1.3 (Software Engineering Institute, 2010), and the ISO/IEC 20000:2005 standard (ISO/IEC 2005a, 2005b, 2010), to manage – on-premise Data Centers - the governing, planning, design, deployment, operation, and improvement of IT services (Mora et al., 2014; 2015). Multiple organizational benefits such as improvements in the service quality, customer satisfaction, reduction of IT downtime, IT staff morale, and financial contribution metrics have been also reported for the utilization of ITSM frameworks and standards (Marrone & Kolbe, 2011; Marrone et al., 2014). <br/><br/>
                However, nowadays, the demand for business services agility supported by IT services has produced the recent emergence of agile, lightweight, or lean ITSM frameworks and standards (Lean IT Association, 2015; FitSM, 2016; Verlaine, 2017; ISO/IEC/IEEE 2018a; 2018b; TSO, 2019; Galup et al., 2020; Mora et al., 2025).  Given the novelty of these agile, lightweight, and lean ITSM frameworks and standards (i.e. Lean IT, VeriSM, ITIL v4, FitSM, ISO/IEC/IEEE 20000-1:2018, and ISO/IEC/IEEE 29110-4-3:2018, among others) and the scarcity of conceptual and empirical studies on them, there is a lack of informative references on the applicability, benefits, and limitations of using agile, lightweight, and lean ITSM frameworks and standards for the ITSM practitioners and scholars as well as a distinct knowledge gap for the global business organizations interested in the utilization of them for managing IT services with an agile approach. <br/><br/>
                Consequently, this special session pursues to advance rigorous and relevant scientific knowledge related to agile IT Services practices applied for IT services released in Cloud from a customer perspective. Whereas there is a high relevance of a similar investigation from the Cloud provider perspective, this special issue aims to help IT Services designers of Cloud customers to plan, analyze, design, assembly, deploy, operate, improve, and retire IT services in the Cloud with agile practices. <br/><br/>
                Hence, due to the recent emergence of agile ITSM approaches and the high relevance of IT services released in Cloud, and the strong pressure for business agility, we require high-quality research papers using any appropriate research method to study the problems related to the theme of this special issue, such as statistical survey, simulation, case study, experiments, design research, or conceptual study. <br/><br/>
                </div>

                <h6 className="par_header">TOPICS OF INTEREST</h6>
                <div className="par">
                All Topics of Interest must be related to IT services released in the Cloud from a customer perspective –and include but are not limited to the following ones – <br/><br/>
                - Conceptual studies on the agile, lightweight, and lean IT service management and engineering practices <br/>
                - Conceptual comparative studies between rigor-oriented and agile, lightweight, or lean IT service management and engineering practices <br/>
                - Statistical survey studies on the implementation of the agile, lightweight, or lean IT service management and engineering practices <br/>
                - Case studies on the implementation of the agile, lightweight, or lean IT service management and engineering practices <br/>
                - Simulation studies – system dynamics, discrete event, agent-based or hybrid- on managerial features of the agile, lightweight, or lean IT service management and engineering practices <br/>
                - Design research studies on specific managerial features of the agile, lightweight, or lean IT service management and engineering practices <br/>
                - Experimental studies on specific managerial features of the agile, lightweight, or lean IT service management and engineering practices <br/>
                - ICT tools and platforms for agile, lightweight, or lean IT service management and engineering practices <br/>
                - Studies on agile IT service managerial implications – economic impacts and costs, organizational benefits and risks, implementation facilitators and inhibitors – of the agile, lightweight, or lean IT service management and engineering practices <br/>
                </div>

                <h6 className="par_header">REFERENCES</h6>
                <div className="par">
                - Agutter, C., van Hove, S., Steinberg, R., & England, R. (2017). VeriSM - A service management approach for the digital age. Van Haren, Netherlands.
                <br/>
                - Bounagui, Y., Mezrioui, A., & Hafiddi, H. (2019). Toward a unified framework for Cloud Computing governance: An approach for evaluating and integrating IT management and governance models. Computer Standards & Interfaces, 62, 98-118.
                <br/>
                - CRN (2020). Website https://www.crn.com/slide-shows/cloud/the-10-biggest-cloud-outages-of-2020/2
                <br/>
                - Eikebrokk, T. R., & Iden, J. (2017). Strategising IT service management through ITIL implementation: model and empirical test. Total Quality Management & Business Excellence, 28(3-4), 238-265.
                <br/>
                - FitSM. (2016). FitSM-2: Objectives and activities, The FitSM Standard Family: Standard for lightweight IT service management 1, version 2.2. Https://www.fitsm.eu/downloads/
                <br/>
                - Galup, S., Dattero, R., & Quan, J. (2020). What do agile, lean, and ITIL mean to DevOps?. Communications of the ACM, 63(10), 48-53.
                <br/>
                - ISO/IEC (2018a). ISO/IEC 20000-1:2018 Information technology — Service management — Part 1: Service management system requirements. International Organization for Standardization.
                <br/>
                - ISO/IEC (2018b). ISO/IEC 29110-4-3:2018 Systems and software engineering — Lifecycle profiles for very small entities (VSEs) — Part 4-3: Service delivery — Profile specification. International Organization for Standardization.
                <br/>
                - ISO/IEC. (2005a). ISO/IEC 20000-1:2005 Information technology - Service management – Part 1: Specification. International Organization for Standardization.  
                <br/>
                - ISO/IEC. (2005b). ISO/IEC 20000-1:2005 Information technology — Service management — Part 2: Code of practice. International Organization for Standardization.
                <br/>
                - ISO/IEC. (2010). ISO/IEC TR 20000-4:2010 Information technology — Service management — Part 4: Process reference model. International Organization for Standardization. 
                <br/>
                - itSMF UK, & Agutter, C. (2012). ITIL Foundation Handbook. The Stationery Office. 
                <br/>
                - Lean IT Association. (2015). Lean IT Foundation - Increasing the Value of IT. Newark, NJ: Lean IT Association.
                <br/>
                - Marrone, M., & Kolbe, L. M. (2011). Impact of IT service management frameworks on the IT organization. Business & Information Systems Engineering, 3(1), 5-18.
                <br/>
                - Marrone, M., Gacenga, F., Cater-Steel, A., & Kolbe, L. (2014). IT service management: A cross-national study of ITIL adoption. Communications of the association for information systems, 34(1), 49, 1-30.
                <br/>
                - Mora, M., Gomez, J. M., O'Connor, R. V., Raisinghani, M., & Gelman, O. (2015). An extensive review of IT service design in seven international ITSM processes frameworks: Part II. International Journal of Information Technologies and Systems Approach (IJITSA), 8(1), 69-90. 
                <br/>
                - Mora, M., Gómez, J. M., Wang, F., & Díaz, E. O. (2025). A Review of the IT Service Design Process. In M. Mora, J. Marx-Gomez, R. O'Connor, & A. Buchalcevova (Eds.), Agile ITSM Frameworks. Balancing Agile and Disciplined Engineering and Management Approaches for IT Services and Software Products, (pp. 248-270). IGI Global. 
                <br/>
                - Mora, M., Raisinghani, M., O'Connor, R. V., Gomez, J. M., & Gelman, O. (2014). An extensive review of IT service design in seven international ITSM processes frameworks: Part I. International Journal of Information Technologies and Systems Approach, 7(2), 83-107.
                <br/>
                - Senyo, P. K., Addae, E., & Boateng, R. (2018). Cloud computing research: A review of research themes, frameworks, methods and future research directions. International Journal of Information Management, 38(1), 128-139.
                <br/>
                - Sharma, S., Chang, V., Tim, U., Wong, J., & Gadia, S. (2016). Cloud-based emerging services systems. International Journal of Information Management, 1-12.
                <br/>
                - Software Engineering Institute. (2010). CMMI for Service Version 1.3, CMMI-SVC v1. 3. CMU/SEI-2010-TR-034. Software Engineering Institute.
                <br/>
                - Totaluptime (2020). Website https://totaluptime.com/notable-cloud-outages-of-2020/
                <br/>
                - TSO (2019). ITIL Foundation: ITIL 4 Edition. The Stationery Office Ltd.
                <br/>
                - Verlaine, B. (2017). Toward an agile IT service management framework. Service Science, 9(4), 263-274.
                <br/>
                - Wu, C., & Buyya, R. (2015). Cloud Data Centers and Cost Modeling: A complete guide to planning, designing and building a cloud data center. Morgan Kaufmann.
                <br/>
                - Wu, C., Buyya, R., & Ramamohanarao, K. (2019). Cloud pricing models: Taxonomy, survey, and interdisciplinary challenges. ACM Computing Surveys (CSUR), 52(6), 1-36.
                </div>

            </div>
          </div>
          

        </div>
      </div>
    );
  }
}

export default Approved_Sesss3;