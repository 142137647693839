import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Keynotes extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div className="par">
            Coming soon...

            {/* <div style={{textAlign: "center"}}>
            <b>The Effect of Tech Savviness of the Design and Interaction of
AI Systems: The Exploration of Trust
</b> <br/>
Professor John C. Murray <br/>
Academic Dean of the Faculty of Technology at the University of Sunderland, UK <br />
Professor of Robotics and Autonomous Systems, University of Sunderland, UK<br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/murray3.jpg" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/murray">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr />

            <div style={{textAlign: "center"}}>
            <b>The Dawn of Cognitive AI, Self-Aware Robotics, and the Industrial Metaverse</b> <br/>
            Prof. Dr. Diego Galar <br/>
            Operation and Maintenance Engineering, <br />
            Lulea University of Technology (LTU), Sweden<br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/galar22.jpg" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/galar">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr /> */}

            {/* <div style={{textAlign: "center"}}>
            <b>On the Way to Realize the 5th Industrial Revolution: Updates</b> <br/>
            Prof. Dr. Eng. George Dimitrakopoulos <br/>
            Harokopio University of Athens, Greece <br />
            Infineon Technologies AG, Munich, Germany <br /> <br />
            <div style={{textAlign: "center"}}>
                <img src="/static/george2.png" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/dimitrakopoulos">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr /> */}

            {/* <div style={{textAlign: "center"}}>
            <span style={{textAlign: "center", color: "white", backgroundColor: "orange", borderRadius: "5px 5px 5px 5px", padding: "2px", width: "4rem", margin: "0 auto", fontSize: "0.75rem"}}>Tutorial</span> 
            &nbsp;<b>Explaining the Design of the Quantum Fourier Transform</b> <br/>
            Professor Leon Deligiannidis, Ph.D. <br/>
            Interim Dean and Professor <br />
            School of Computing and Data Science, Wentworth Institute of Technology, Massachusetts, USA<br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/leon.jpg" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/deligiannidis">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr /> */}

            {/* <div style={{textAlign: "center"}}>
            <b>The Future is Here: AI & LLMs</b> <br/>
            Massoud Alibakhsh <br/>
            Founder & CEO <br />
            OMADEUS <br />
            Atlanta, USA
            <br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/alibakhsh.jpeg" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/alibakhsh">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr /> */}

            {/* <div style={{textAlign: "center"}}>
            <b>Integrating Advanced AI Algorithms with Scientific Articles; <br />
            Large Language Models (LLMs) and Beyond
            </b> <br/>
            Dr. Soheyla Amirian <br/>
            University of Georgia, Georgia, USA <br />
            <div style={{textAlign: "center"}}>
                <img src="/static/Amirian.png" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/amirian">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr /> */}

            {/* 

            <div style={{textAlign: "center"}}>
            <b>Artificial Intelligence and Chatbots</b> <br/>
            Dr. Jim Crowder <br/>
            Systems Fellow, CAES Advanced Program Development, USA <br /> <br />
            <div style={{textAlign: "center"}}>
                <img src="/static/crowder2.png" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/crowder">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr />

            <div style={{textAlign: "center"}}>
            <b>Prediction + Explanation: Explainable Machine Learning in Healthcare</b> <br/>
            Dr. Ahmad P. Tafti <br/>
            Director,  Pitt HexAI Research Laboratory,<br />
            Department of Health Information Management,<br />
            School of Health and Rehabilitation Sciences, <br />
            (Secondary appointment: Intelligent Systems Program (ISP), School of Computing and Information) <br />
            University of Pittsburgh, PA, USA <br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/tafti.png" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/tafti">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr />

            <div style={{textAlign: "center"}}>
            <b>Cyber Security, Cyberwar, Hacking, Privacy, and Governmental/Personal Data Breaches</b> <br/>
            Prof. Levent Ertaul <br/>
            Chair, Department of Computer Science <br />
            California State University, East Bay, California, USA<br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/levent.jpg" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/ertaul">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr />

            <div style={{textAlign: "center"}}>
            <b>What Can we Learn from Student Course-Grade Data?</b> <br/>
            Prof. Gary Weiss <br/>
            Professor, Computer & Information Science Department, Fordham University, USA<br /><br />
            <div style={{textAlign: "center"}}>
                <img src="/static/weiss2.png" height="150px" alt="" style={{borderRadius: "50%"}}/>
            </div>
            <br />
            <div style={{textAlign: "center"}}>
                    <Link to="/csce2025/keynotes/weiss">
                      <div className="readMoreBtnAS">
                        <b> Read More </b>
                      </div>
                    </Link>
                </div>   
            </div>
            <hr /> */}

    </div>
  <br />

<h4 className="par_header">Past Distinguished Speakers of CSCE</h4>
            <div className="par">
            
            <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. David Patterson</div>
                      Fellow: IEEE, ACM, AAAS & Member, National Academy of Engineering; Shared with John Hennessy, the IEEE John von Neumann Medal; University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to RISC processor design)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/patterson.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Ian Foster</div>
                      Fellow: ACM, AAAS, BCS; Distinguished Fellow, Argonne National Lab.; Distinguished Professor, University of Chicago
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Introduced Grid Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/foster.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>The Late Prof. Lotfi A. Zadeh</div>
                      Fellow: IEEE, AAAS, ACM, AAAI, IFSA;
                      Member: National Academy of Engineering
                      University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Fuzzy Logic)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/zadeh.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. H. J. Siegel</div>
                      Fellow: IEEE & ACM; Distinguished Professor
                      Colorado State University (formerly at Purdue)
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to Heterogeneous Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/siegel.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>The Late Prof. John Holland</div>
                      University of Michigan
                      Ann Arbor, Michigan
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Genetic Algorithms)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/holland.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Ruzena Bajcsy</div>
                      Member: National Academy of Eng., National Academy of Science; Fellow: IEEE, ACM, AAAI; Director, CITRIS
                      University of California, Berkeley
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to AI, HCI & vision)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bajcsy.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Firouz Naderi</div>
                      Former Director, Solar System Exploration, NASA's JPL;
                      Former NASA's Program Manager for Mars Explortion
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Contributed to some of America's most iconic robotic space missions)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/naderi.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. John R. Koza</div>
                      Former Consulting Prof., Stanford University
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Father of Genetic Programming)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Koza.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. David Lorge Parnas</div>
                      Fellow: IEEE, ACM, RSC, CAE, GI; MRIA
                      McMaster University, Canada
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer of Software Engineering)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/parnas-photo_02.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. John Gustafson</div>
                      Recipient of Inaugural Gordon Bell Prize;
                      CTO, Ceranovo, Inc.
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Inventor of Gustafson's Law)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Gustafson.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Anousheh Ansari</div>
                      Chairwoman, Prodea Systems; Space Ambassador;
                      <br></br>
                      <div style={{color: '#4b6584'}}>(First Female Space Tourist)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/anousheh.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>



              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Eugene H. Spafford</div>
                      Fellow: IEEE, ACM, AAAS,
                    Executive Director, CERIAS, Purdue University
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Renowned for first analyzing the Morris Worm)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/spafford.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Victor Raskin</div>
                      Distinguished Professor, Purdue University, USA
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Author of Semantic Mechanisms of Humor & Ontological Semantics)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/raskin.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Amit Sheth</div>
                      Fellow, IEEE; LexisNexis Eminent Scholar; Director, Kno.e.sis
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer in the area of Semantics and Social Data)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/sheth.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Vladimir S. Cherkassky</div>
                      Fellow, IEEE;
University of Minnesota; Former Director, NATO Advanced Study Institute (ASI)
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer in the area of Learning from Data)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/cherkassky.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Barry Vercoe</div>
                      Founding member of MIT Media Lab, MIT,
Massachusetts Institute of Technology
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Inventor of Csound, the underlying language for MPEG-4)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Vercoe.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Haym Hirsh</div>
                      Dean, Computing & Information Science, Cornell University
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneering contributions to Recommendation Systems)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/hirsh.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Alfred Inselberg</div>
                      Tel Aviv University, Israel & UCLA, USA
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Inventor of parallel coordinates; his work is praised by Stephen Hawking)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/inselberg.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Rajkumar Buyya</div>
                      Fellow, IEEE; Distinguished Professor;
University of Melbourne, Australia
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer in the area of Cloud Computing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/Buyya.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Dr. K. Eric Drexler</div>
                      Chief Technical Advisor, Nanorex
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Founding Father of Nanotechnology)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/drexler.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Viktor K. Prasanna</div>
                      Fellow: IEEE, ACM;
University of Southern California
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer in the area of Reconfigurable Systems)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/prasanna.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Brian D. Athey</div>
                      Co-Director, Michigan Institute for Data Science (MIDAS);
University of Michigan Medical School
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Pioneer in the area of Computational Medicine)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/athey.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Prof. Diego Galar</div>
                      Lulea University, Sweden
                      <br></br>
                      <div style={{color: '#4b6584'}}>(Known for contributions to Big Data Analytics for Manufacturing)</div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/diego.gif" alt=""/>
                  </Col>
                </Row>
              </Grid>
            
          
          </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynotes;