import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Sess_P extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Session Proposals</h4>
            <h6 className="par_header">PROPOSAL FOR ORGANIZING WORKSHOPS & SESSIONS</h6>
            <div className="par">
            Each conference is composed of a number of tracks. A track can be a session, a workshop, or a symposium. A session will have at least 6 papers; a workshop at least 12 papers; and a symposium at least 18 papers. Track chairs will be responsible for all aspects of their tracks, including: soliciting papers, reviewing, selecting, ... The names of track chairs will appear as track editors in the conference proceedings and they will be presented achievement awards at appropriate professional levels during the Award Ceremony on site at the congress. Track chairs who attract a sufficient number of solid papers can propose to edit books with a major publisher based on the extended versions of the papers accepted in their tracks (the congress will facilitate and help such track chairs to get the publisher's approval: such as Springer, Elsevier, ...).
            <br/> <br/>
            <b>Proposals to organize tracks (sessions, workshops, or symposiums) should include the following information:</b> <br/>
            - Name and address (+ email) of proposer <br/>
            - Proposer's biography<br/>
            - Title of track<br/>
            - A 100-word description of the topic of the track<br/>
            - The name of the conference the track is submitted for consideration (i.e., ACC, BIOCOMP, ...)<br/>
            - A short description on how the track will be advertised (in most cases, track proposers solicit papers from colleagues and researchers whose work is known to the track proposer). <br/>

            <br/><br/>
            E-mail your track proposal to cs@american-cse.org . We would like to receive the track proposals as soon as possible. (<Link to="/csce2025/deadlines">See Important Date</Link>).
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Sess_P;