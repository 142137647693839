import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Spec_issue extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">CSCI/CSCE Proceedings, Books & Journal Special Issues</h4>
            <div className="par">
            For a recent and a small subset of the CSCI/CSCE Proceedings and books (and journal special issues) that have been published based on the extended versions of many of our congress papers, see the listing below. Some of these proceedings, books and journal special issues have already received the top 25% downloads in their respective fields and/or identified as "Highly Accessed" by publishers and/or science citation index trackers. As of January 30, 2025, there has been 1,115,000 book chapter/paper downloads from the following publications.) Many of these books have been published in multiple editions due to high demand by the research community and also by WorldCat member libraries worldwide.
            <br/>
            <br />
            2024 Proceedings of World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE 2025): <br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>
            <br />
            + 19 more books are in the publication pipeline (will be made available in a few weeks.)
            <hr/>

            <Grid fluid>
            <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2023 Proceedings of World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE 2023)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, and Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
                

            <Grid fluid>
            <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Reliability and Risk Analysis in Engineering and Medicine</div>
                      Co-editors: Chandrasekhar Putcha, Subhrajit Dutta, Sanjay K. Gupta
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-80454-1" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/series11769.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
                
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Transactions on Computational Science and Computational Intelligence</div>
                      Editor: Prof. Hamid R. Arabnia
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://www.springer.com/series/11769" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be1.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Advances in Security, Networks, and Internet of Things
                      </div>
                      Co-editors: Kevin Daimi, Hamid R. Arabnia, Leonidas Deligiannidis, Min-Shiang Hwang, Fernando G. Tinetti <br/>
                      Includes all accepted papers of SAM, ICWN, ICOMP, ESCS 2020
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-71017-0" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be2.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Advances in Computer Vision and Computational Biology</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Hayaru Shouno, Fernando G. Tinetti, Quoc-Nam Tran <br/>
                      Includes all accepted papers of IPCV, HIMS, BIOCOMP, BIOENG 2020
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-71051-4" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be3.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Advances in Software Engineering, Education, and e-Learning</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran <br/>
                      Includes all accepted papers of FECS, FCS, SERP, EEE 2020
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-70873-3" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be4.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Advances in Artificial Intelligence and Applied Cognitive Computing</div>
                      Co-editors: Hamid R. Arabnia, Ken Ferens, David de la Fuente, Elena B. Kozerenko, José Angel Olivas Varela, Fernando G. Tinetti <br/>
                      Includes all accepted papers of ICAI, ACC 2020
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-70296-0" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be5.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Advances in Parallel & Distributed Processing, and Applications</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Michael R. Grimaila, Douglas D. Hodson, Kazuki Joe, Masakazu Sekijima, Fernando G. Tinetti <br/>
                      Includes all accepted papers of PDPTA, CSC, MSV, GCC 2020
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-69984-0" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be6.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Advances in Data Science and Information Engineering</div>
                      Co-editors: Robert Stahlbock, Gary M. Weiss, Mahmoud Abou-Nasr, Cheng-Ying Yang, Hamid R. Arabnia, Leonidas Deligiannidis <br/>
                      Includes all accepted papers of ICDATA, IKE 2020
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://link.springer.com/book/10.1007/978-3-030-71704-9" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/be7.jpg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2020 International Conference on Computational Science and Computational Intelligence (CSCI)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2019 International Conference on Computational Science and Computational Intelligence (CSCI)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2018 International Conference on Computational Science and Computational Intelligence (CSCI)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2017 International Conference on Computational Science and Computational Intelligence (CSCI)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/8554238/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2016 International Conference on Computational Science and Computational Intelligence (CSCI)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/7879541/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2015 International Conference on Computational Science and Computational Intelligence (CSCI)</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/7420813/proceeding" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2014 International Conference on Computational Science and Computational Intelligence (CSCI) - Volume 1</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/6820887/proceeding?isnumber=6822065" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>2014 International Conference on Computational Science and Computational Intelligence (CSCI) - Volume 2</div>
                      Co-editors: Hamid R. Arabnia, Leonidas Deligiannidis, Fernando G. Tinetti, Quoc-Nam Tran                    
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://ieeexplore.ieee.org/xpl/conhome/6820887/proceeding?isnumber=6822285" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/ieeeX.png" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>



            {/*  */}

            <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Elsevier/MK: Emerging Trends in Image Processing, Computer Vision and Pattern Recognition</div>
                      Co-editors: Prof. Leonidas Deligiannidis and Prof. Hamid R. Arabnia; <br/>
                      About 650+ pages.
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://store.elsevier.com/product.jsp?isbn=9780128020456&pagename=" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b1.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Software Tools and Algorithms for Biological Systems; Series: Advances in Experimental Medicine and Biology, Vol 696.</div>
                      Co-editors: Prof. Quoc-Nam Tran and Prof. Hamid R. Arabnia; <br/>
                      780 pages (Awarded the top 25% downloads in the field.)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.springer.com/us/book/9781441970459" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b2.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Advances in Computational Biology; Series: Advances in Experimental Medicine and Biology, Vol 680.</div>
                      Co-editors: Prof. Quoc-Nam Tran and Prof. Hamid R. Arabnia; <br/>
                      700+ pages (Awarded the top 25% downloads in the field.)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://http://link.springer.com/book/10.1007%2F978-1-4419-5913-3" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b3.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Journal of BMC Systems Biology: Special Journal Issue "Bioinformatics and Computational Biology", (Vol. 5, Supp. 3); Impact Factor: 2.44.</div>
                      Co-editors: Ke Zhang, Yunlong Liu, Hamid R. Arabnia; <br/>
                      200+ pages. (Many articles of this issue is marked as "Highly accessed".)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.biomedcentral.com/1752-0509/5?issue=S3" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b4.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Elsevier/MK: Emerging Trends in ICT Security (Information and Communications Technology).</div>
                      Co-editors: Prof. Babak Akhgar and Prof. Hamid R. Arabnia; <br/>
660 pages. (One of the most highly accessed books in the field.)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://store.elsevier.com/Emerging-Trends-in-ICT-Security/isbn-9780124114746/" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b5.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Journal of BMC Medical Genomics: Special Journal Issue "Bioinformatics and Computational Biology", (Vol. 6, Supp. 1); Impact Factor: 2.87.</div>
                      Co-editors: Ke Zhang, Hamid R. Arabnia, Mehdi Pirooznia; <br/> 240+ pages.
(Some articles of this issue is marked as "Highly accessed".)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.biomedcentral.com/bmcmedgenomics/supplements/6/S1" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b6.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Transactions on Computational Science and Computational Intelligence (multiple books).</div>
                      Co-editors: Hamid R. Arabnia and Pietro Cipresso
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.springer.com/series/11769" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b7.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Journal of BMC Genomics: Special Journal Issue "Bioinformatics and Computational Biology", (Vol. 9, Supp. 1); Impact Factor: 3.99.</div>
                      Co-editors: Jack Y. Yang, Mary Qu Yang, Mengxia Zhu, Youping Deng, Hamid R. Arabnia. <br/> 250+ pages.
(Some articles of this issue is marked as "Highly accessed".)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://bmcgenomics.biomedcentral.com/articles/supplements/volume-15-supplement-11" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b8.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Elsevier/MK: Emerging Trends in Computer Science and Applied Computing (multiple books.)</div>
                      Editor-in-chief: Prof. Hamid R. Arabnia.
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://store.elsevier.com/Emerging-Trends-In-Computer-Science-And-Applied-Computing/EST_SER-8400019/" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b1.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Journal of BMC Genomics: Special Journal Issue "Advances in Big Data Analytics",
(Vol. 15, Supp. 11); Impact Factor: 3.99.</div>
Co-editors: Xiang Qin, Weida Tong, Hamid R. Arabnia, Youping Deng, Dong Xu.  <br/> 100+ pages. (Some articles of this issue is marked as "Highly accessed".)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.biomedcentral.com/bmcgenomics/supplements/15/S11" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b8.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Elsevier/MK: Emerging Trends in Computational Biology, Bioinformatics, and Systems Biology - Algorithms and Software Tools.</div>
                      Co-editors: Prof. Quoc-Nam Tran and Prof. Hamid R. Arabnia; <br/> 600+ pages.
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://store.elsevier.com/Emerging-Trends-in-Computational-Biology-Bioinformatics-and-Systems-Biology/Hamid-Arabnia/isbn-9780128025086/" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b1.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Resilient Computer System Design.</div>
                      Co-editors: Victor Castano and Igor Schagaev. <br/> 300+ pages.
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.springer.com/ engineering/signals/book/978-3-319-15068-0

" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bb.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Elsevier/MK: Emerging Trends in Computational Biology, Bioinformatics, and Systems Biology - Systems and Applications.</div>
                      Co-editors: Prof. Hamid R. Arabnia and Prof. Quoc-Nam Tran; <br/> 600+ pages.
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://world-comp.org/elsevier-morgan-kaufman-CBB/" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/b1.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Real World Data Mining Applications (Series: Annals of Information Systems); Volume 17.</div>
                      Co-editors: Mahmoud Abou-Nasr, Stefan Lessmann, Robert Stahlbock, Gary M. Weiss. <br/> 420+ pages.
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://http://link.springer.com/book/10.1007%2F978-3-319-07812-0" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bb2.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Journal of BMC Bioinformatics: Special Journal Issue (Vol 15, Supp. 17); Impact Factor: 2.58.</div>
                      Co-editors: Weida Tong, Beifang Niu, Hamid R. Arabina, Dong Xu, Yonlong Liu, Zhongxue Chen. <br/> 140+ pages.
 (Some articles of this issue is marked as "Highly accessed".)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.biomedcentral.com/bmcbioinformatics/supplements/15/S17" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      {/* <img src="/static/b1.jpeg" alt=""/> */}
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: The Journal of Supercomputing (multiple journal issues)</div>
                      Editor-in-chief: Hamid R. Arabnia <br/>
ISBN #: 0920-8542 (print version); ISSN: 1573-0484 (electronic version);
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://www.springer.com/computer/swe/journal/11227" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bb3.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Special Issue in Annals of Information Systems; Data Mining; Vol. 8</div>
                      Co-editors: Robert Stahlbock, Sven F. Crone, Stefan Lessmann; <br/>
400 pages. ISBN: 978-1-4419-1279-4 (Print) 978-1-4419-1280-0 (Online)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="http://http://link.springer.com/book/10.1007/978-1-4419-1280-0" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bb4.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>
              <hr/>
              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={19}>
                    <div>
                      <div style={{color: '#4b6584', fontWeight: "bold"}}>Springer: Real World Data Mining Applications; in Annals of Information Systems; Vol. 17;</div>
                      Co-editors: Mahmoud Abou-Nasr, Stefan Lessmann, Robert Stahlbock, Gary M. Weiss; <br/> 420 pages.
ISBN: 978-3-319-07811-3 (Print) 978-3-319-07812-0 (Online)
                      <br></br>
                      <div style={{color: '#4b6584'}}><Icon icon='link' size="1x"/> <a href="https://www.springer.com/kr/book/9783319078113" target="_blank" rel="noopener noreferrer">Link</a></div>
                    </div>
                  </Col>
                  <Col xs={5}>
                      <img src="/static/bb5.jpeg" alt=""/>
                  </Col>
                </Row>
              </Grid>            
          
          </div>
          </div>
          

        </div>
      </div>
    );
  }
}

export default Spec_issue;