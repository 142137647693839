import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsGCC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">GCC'25 - The 21st Int'l Conf on Grid, Cloud, and Cluster Computing</h4>
            <div className="par">
            GCC  is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2025 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'25).
            <br/><br/>
We anticipate having between 1,000 and 1,500 participants in the Congress. The congress includes 21 major tracks composed of: 112 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 21-24, 2025. Last year, the Congress had attracted speakers/authors and participants affiliated with over 148 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
<br/><br/>
The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
<br/><br/>
<b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings (Research Books) and indexed in major Science Citation Indexation databases (including: Scopus; DBLP; EI Engineering Index; Compendex; Inspec databases; Springer Links; Google Scholar; CP Citation Index, CPCI; part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.)
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            <b>Past Publications of Proceedings/Books can be found at:</b><br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>  <br />
            <a href="https://american-cse.org/csce2025/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/special_issues</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings</a><br />  
            <br /> 
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Topics of interest include, but are not limited to, the following: <br/>
            <b>Grid Computing:</b><br/>
            - Software engineering support for grid computing <br/>
            - Software integration technologies in grid systems<br/>
            - Big Data management on Grid<br/>
            - Grid technology and security<br/>
            - Grid computing virtual organizations<br/>
            - Grid information services<br/>
            - Grid middleware and toolkits<br/>
            - Grid monitoring, management, and organization tools<br/>
            - Evolution of grid standards<br/>
            - User development environment and programming tools for grid computing<br/>
            - Resource management, scheduling, load balancing & run-time environments<br/>
            - Information/knowledge grids<br/>
            - Autonomic & utility computing on global grids<br/>
            - Architectures for grid computing and fabrics<br/>
            - Data grid management systems<br/>
            - Metadata, ontologies, and provenance<br/>
            - Resource discovery<br/>
            - Web services and web security in grid computing<br/>
            - Wireless enhanced grid systems<br/>
            - Grid and cluster integration<br/>
            - e-Science, e-Business applications, and virtual instrumentation<br/>
            - Grid object metadata and schemas<br/>
            - Grid survivability<br/>
            - Grid application and deployment environments<br/>
            - Interconnects and protocols for grids<br/>
            - Evolution of grid - recent progress<br/>
            - Major grid initiatives<br/>
            - Grid computing scenarios and applications in science,engineering, and commerce (life science, CFD grids, business process grids, physics grids, ...)<br/>
            <br/>
            <b>Cloud Computing:</b><br/>
            - Big Data management on Cloud<br/>
            - Hardware as a service<br/>
            - SaaS, PaaS, and IaaS<br/>
            - SOA and Cloud computing<br/>
            - Green information technology<br/>
            - Application portability<br/>
            - Cloud scalability<br/>
            - Cloud management and databases<br/>
            - Use of cloud for high-performance computing<br/>
            - Cloudsourcing<br/>
            - Cloud interoperability<br/>
            - Web-scale computing<br/>
            - Data encryption<br/>
            - Virtual Private Inter-Cloud<br/>
            - Cloud reliability and secure Cloud computing<br/>
            - Cloud automation and mobility<br/>
            - Cloud policies and compliance<br/>
            - Cloud federation<br/>
            - Cloud enablement<br/>
            - Consumption-based billing<br/>
            - Cloud serviceability and architecture<br/>
            - Cloud computing and SMBs<br/>
            - Cloud applications<br/>
            - Enterprise Cloud Orchestration<br/>
            - Fabric Computing<br/>
            - Eclipse and Cloud computing<br/>
            - Cloud internetworking<br/>
            <br/>
            <b>Cluster Computing:</b><br/>
            - System modeling and architecture design<br/>
            - Scalability (scalable systems and methods)<br/>
            - Novel machine and network architectures<br/>
            - Software tools and utilities<br/>
            - Programming models, environments, and languages<br/>
            - Performance modeling and evaluation systems<br/>
            - Resource management and scheduling methods<br/>
            - System management, administration, and support<br/>
            - Novel algorithms and emerging applications<br/>
            </div>
                      
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsGCC;