import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsBIOENG extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">BIOENG'25 - The 11th Int'l Conf on Biomedical Engineering and Sciences</h4>
            <div className="par">
            BIOENG  is an international conference that serves researchers, scholars, professionals, students, and academicians who are looking to both foster working relationships and gain access to the latest research results. It is being held jointly (same location and dates) with a number of other research conferences; namely, The 2025 World Congress in Computer Science, Computer Engineering, and Applied Computing (CSCE'25).
            <br/><br/>
            We anticipate having between 1,000 and 1,500 participants in the Congress. The congress includes 21 major tracks composed of: 112 technical, research, and panel sessions as well as a number of keynote lectures and tutorials; all will be held simultaneously, same location and dates: July 21-24, 2025. Last year, the Congress had attracted speakers/authors and participants affiliated with over 148 different universities (including many from the top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple, SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson, BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including: Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH, DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US national laboratories (including, NASA National Aeronautics and Space Administration, ANL Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers discussing Intellectual Property issues. Last year, 54% of attendees were from academia, 25% from industry; 20% from government and funding agencies; and 1% unknown. About half of the attendees were from outside USA; from 69 nations.
            <br/><br/>
            Biomedical Engineering and Science is the utility of engineering methods and design concepts to medicine and biology for healthcare purposes. This is a multi-disciplinary field that seeks to close the gap between engineering science and medicine. It combines the problem solving skills of engineering with medical and biological sciences to advance health care treatment, including diagnosis, monitoring, and therapy.  
            <br/><br/>
            The primary goal of this conference is to provide a platform for researchers, scientists, industry experts and scholars to share their novel ideas and research results on the application of human cognition models in various practical computing applications. Through this conference, the organizers would like to develop an interdisciplinary venue to contribute and discuss the ongoing innovations, applications and solutions to challenging problems of engineering human brain processes, learning mechanisms and decision making processes.
            <br/><br/>
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings (Research Books) and indexed in major Science Citation Indexation databases (including: Scopus; DBLP; EI Engineering Index; Compendex; Inspec databases; Springer Links; Google Scholar; CP Citation Index, CPCI; part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.)
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            <b>Past Publications of Proceedings/Books can be found at:</b><br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>  <br />
            <a href="https://american-cse.org/csce2025/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/special_issues</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings</a><br />  
            <br /> 
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            Biomedical Engineering and Science consists of research and development, spanning a broad array of topics, including:
            <br/>
            <b>Medical Devices:</b> <br/>
            - Data and Signal Acquisition <br/>
            - Robotics and Medicine<br/>
            - Biomedical Electronics<br/>
            - Intelligent Measurement and Instrumentation<br/>
            - Bioinstrumentation<br/>
            - Biomaterials<br/>
            - Biomechatronics<br/>
            - Biosensors and Transducers<br/>
            - Dental Engineering<br/>
            - Electronic Medical Devices<br/>
            - Lasers and Optical Systems<br/>
            - Microtechnology and BioMEMS<br/>
            - Noninvasive Measurement methods<br/>
            - Reliability and Failure<br/>
            <br/>
            <b>Modeling and Simulation:</b> <br/>
            - Biomedical Computing <br/>
            - Surgery Simulation<br/>
            - Virtual Reality<br/>
            - Bio-Aquatic System Design and Protection<br/>
            - Bio-Environmental Engineering<br/>
            - Electromagnetic Field Simulation<br/>
            - Sports and Ergonomics Simulation<br/>
            <br/>
           <b>Biomechanics:</b> <br/>
          - Gait Analysis<br/>
          - Assistive Technology<br/>
          - Bone and Joint Engineering<br/>
          - Musculoskeletal Modeling<br/>
          - Physiotherapy and Sports Biomechanics<br/>
          - Prosthetics and Orthotics<br/>
          - Rehabilitation Engineering<br/>
          - Tissue Mechanics<br/>
          <br/>
          <b>Biomedical Imaging and Optics:</b><br/>
          - Nanoparticles and Cell Imaging<br/>
          - Medical Image Processing<br/>
          - Integrated Medical Image Analysis<br/>
          - Biomedical Signal Processing<br/>
          - Biophotonics<br/>
          - Computed Tomography<br/>
          - Data Representation and Visualization<br/>
          - Magnetic Resonance Imaging (MRI) <br/>
          - Magnetic Resonance Spectroscopy<br/>
          - Medical Data Storage and Compression<br/>
          - Nuclear Medicine<br/>
          - Optical Imaging<br/>
          - Ultrasound Imaging<br/>
          <br/>
          <b>Biomedical and Nanomedical Systems:</b><br/>
          - Monitoring Systems<br/>
          - Surgical Instruments<br/>
          - Devices for Minimally Invasive Surgery<br/>
          - Pace Makers<br/>
          - Artificial Heart Valves<br/>
          - Stents<br/>
          - Catheters<br/>
          - Implants<br/>
          <br/>
          <b>Biomaterial; Material and Computer Systems used for:</b><br/>
          - Dental Implants for Tooth Fixation<br/>
          - Joint Replacements<br/>
          - Bone Plates<br/>
          - Artificial Ligaments and Tendons<br/>
          - Blood Vessel Prostheses<br/>
          - Skin Repair Devices (Artificial Tissue) <br/>
          - Cochlear Replacements<br/>
          - Implants<br/>
          - Drug Delivery Mechanisms<br/>
          <br/>
          <b>Biomedical Engineering Education and Training:</b><br/>
          - Degree Programs in Biomedical Engineering and Curriculum Design<br/>
          - Certificate Programs<br/>
          - Online Classes and Mooc (Massive Open Online Courses) <br/>
          - Instructional Technologies<br/>
          - Methods used to Manage Multidisciplinary Nature of Biomedical Engineering<br/>
          - Re-enforcement Learning Technologies<br/>
          <br/>
          <b>Biomedical Engineering Education and Training:</b><br/>
          - Delivery Devices and Mobile Technologies<br/>
          - Computer Interfaces and Wearable Devices<br/>
          - Biomedical Robotics<br/>
          - Drug Design and Discovery<br/>
          - Cancer Technologies<br/>
          - Cardiovascular Engineering and Systems<br/>
          - Cellular and Molecular Bioengineering<br/>
          - Neural Engineering<br/>
          - Orthopedic and Rehabilitation<br/>
          - Respiratory Bioengineering<br/>
          - Stem Cell Engineering<br/>
          - Tissue Engineering<br/>
          - Micro Technologies<br/>
          - Systems Approaches to Therapy and Therapeutics<br/>
          - Translational Biomedical Engineering<br/>
          - Genetic Engineering<br/>
          - Pharmaceutical Engineering<br/>
          - Bionics<br/>
            </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsBIOENG;