import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PresSch extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Program/Presentation Schedules</h4>
            <div className="par">
                The presentation schedule will be provided during the month of July 2025.
                {/* Authors and speakers were given the following options (authors were to provide us with their OPTION and any scheduling constraints by July 7, 2025). All authors' requests received by July 15, 2025 have been granted.
                <br/><br/>
                <b>- OPTION 1: </b> Presenting the paper on site at the congress (face-to-face)
                <br/>
                <b>- OPTION 2: </b> Presenting the paper remotely: using Zoom (interactive)
                <br/>
                <b>- OPTION 3: </b> Presenting the paper remotely (non-interactive). The link to these pre-recorded presentations will later be included in the "Book of Abstracts" and will be made available on the CSCE 2025 website at <a href="https://www.american-cse.org/csce2025/program" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csce2025/program</a> AFTER the conference.
                
                <br /><br />
                <div style={{fontSize: "x-large"}}>
                <b>All times that appear in the Presentation Schedules are given in Las Vegas time (i.e., USA Pacific Time Zone).</b>
                </div>
                <br />
                To download/view a copy of the Presentation Schedules in .pdf (Date of update: September 2, 2023)  <a href="/static/NEW-2025-CSCE-BOOKLET-09-02-2025.pdf" target="_blank" rel="noopener noreferrer">CLICK HERE</a> <br />
                To download/view a copy of the Presentation Schedules in .docx (Date of update: September 2, 2023) <a href="/static/NEW-2025-CSCE-BOOKLET-09-02-2025.docx" target="_blank" rel="noopener noreferrer">CLICK HERE</a> <br />
                To download/view a copy of the Table of Contents in .docx  <a href="/static/2025-CSCE-TOC.docx" target="_blank" rel="noopener noreferrer">CLICK HERE</a> <br />
                To download/view a copy of the Cover of the Book of Presentation Schedules <a href="/static/2025-CSCE-Schedules-Cover.pdf" target="_blank" rel="noopener noreferrer">CLICK HERE</a>

                <br /><br />
                The online/ZOOM sessions are clearly identified as such in the schedules (see above). The ZOOM links are already emailed to the authors of accepted papers (please check your spam filter). If you cannot find the ZOOM link, then please send an email to the following address: (in your email, you should provide the following information: Your name + your email address + your paper ID number + title of paper): <br />
                <br />
                <div style={{fontSize: "large"}}>
                    <b>CSCE.ZOOM@gmail.com</b>
                </div>
                <br />
                <br />
                To download/view a copy of the Book of Abstracts: <a href="/static/CSCE25-book-abstracts.pdf" target="_blank" rel="noopener noreferrer">CLICK HERE</a>
                <br />
                To download/view a copy of the Cover of the Book of Abstracts: <a href="/static/CSCE25-Book-of-Abstracts-cover.pdf" target="_blank" rel="noopener noreferrer">CLICK HERE</a>
                <br />
                <br /> */}
                {/* All times provided in the schedule are in Las Vegas time (i.e, USA Pacific Time Zone). */}
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default PresSch;