import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote12 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>On the Way to Realize the 5th Industrial Revolution: Updates</b> <br/>
            Prof. Dr. Eng. George Dimitrakopoulos<br/>
            Harokopio University of Athens, Greece <br />
            Infineon Technologies AG, Munich, Germany <br />

            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            The industrial world is rapidly moving towards its 5th industrial revolution (a.k.a. “Industry5.0”). Industry5.0 is reflected on the digital sovereignty in comprehensively sustainable production, through adopting, extending and implementing AI-enabled hardware, as well as AI tools & methods and semiconductors technology across the whole industrial value chain. It is expected that in doing so, manufacturing costs will be decreased, while at the same time, product quality will be increased through AI-enabled innovation, time-to-market will be shortened and user acceptance of versatile technology offerings will be achieved and global supply chains stabilized. The above will, in turn, foster a sustainable development, in an economical, ecological and societal sense. <br />
            <br />
            This keynote will discuss on the fundamental research areas relevant to further advancing the digitalized industry, its achievements so far, as well as the challenges confronted, in order to boost industrial competitiveness through interdisciplinary innovations, establishing sustainable value chains and therefore contribute to the Digital Sovereignty.

            </div>

            <h6 className="par_header">Speaker's Biography:</h6>
            <div className="par">
            Prof. Dr. Eng. George Dimitrakopoulos (m) is an associate professor at Harokopio University of Athens (HUA) - Greece, department of Informatics and Telematics (2010-). 
 <br /> He is also a coordinator (ext.) at the department of research and development funding at Infineon Technologies A.G. (IFAG) in Germany (2018-), where he manages large scale projects. 
<br />He has been acting also as an R&D consultant for several large enterprises, as well as startups in the area of cognitive communication systems. 
<br />He has received his bachelor degree in Electrical and Computer Engineering in 2002 and his Ph.D. in 2007, from the National Technical University of Athens, and the University of Piraeus, respectively. 
<br />He is the author of 3 books on vehicular communications and autonomous driving, as well as more than 200 publications in international journals and conferences. 
<br />In the last 20 years he has been involved in numerous internationally funded R&D projects. 
<br />He is listed among the top 2% of scientists worldwide according to Stanford University (2021, 2022). 
<br />His research interests include the design and development of cognitive (knowledge-based) management algorithms for vehicular communications, as well as the design and development of strategies for the optimization of wireless networks based on cognitive networking principles.


            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/george2.png" height="250px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote12;