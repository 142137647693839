import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PapC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Paper Categories</h4>
            <div className="par">
            Authors are given the opportunity to submit their papers for evaluation in one of the following three paper categories:
            <br/><br/>
        
            <h6>1. Full/Regular Research Papers (maximum of 15 pages using single-column or maximum of 8 pages using double-column format):</h6>
            Regular Research Papers should provide detail original research contributions. They must report new research results that represent a contribution to the field; sufficient details and support for the results and conclusions should also be provided. The work presented in regular papers are expected to be at a stage of maturity that with some additional work can be published as journal papers.
            <br/><Link to="/csce2025/presentation_modes">Presentation Mode Options</Link>
            <br/><br/>
            <h6>2. Short Research Papers (maximum of 11 pages using single-column or maximum of 5 pages using double-column format):</h6>
             Short Research Papers report on ongoing research projects. They should provide overall research methodologies with some results. The work presented in short papers are expected to be at a stage of maturity that with some additional work can be published as regular papers.
             <br/><Link to="/csce2025/presentation_modes">Presentation Mode Options</Link>
            <br/><br/>
            <h6>3. Extended Abstract/Poster Papers (maximum of 5 pages using single-column or maximum of 3 pages using double-column format):</h6>
             Poster papers report on ongoing research projects that are still in their infancy (i.e., at very early stages). Such papers tend to provide research methodologies without yet concrete results.
             <br/>
             <Link to="/csce2025/presentation_modes">Presentation Mode Options</Link>
            <br/><br/>
                    
            {/* <h6>- Full/Regular Research Papers (maximum of 6 pages):</h6>
              Regular Research Papers should provide detail original research
              contributions. They must report new research results that
              represent a contribution to the field; sufficient details and
              support for the results and conclusions should also be provided.
              The work presented in regular papers are expected to be at a
              stage of maturity that with some additional work can be
              published as journal papers. <br/>
              <Link to="/csce2025/paper_category_rrp">Read More</Link>
              <br/><br/>
            <h6>- Short Papers (maximum of 4 pages):</h6>
              Short papers report on ongoing research projects. They should
              provide overall research methodologies with some results. The
              work presented in short papers are expected to be at a stage of
              maturity that with some additional work can be published as
              regular papers. <br/>
              <Link to="/csce2025/paper_category_srp">Read More</Link>
              <br/><br/>
            <h6>- Poster Papers (maximum of 2 pages):</h6>
              Poster papers report on ongoing research projects that are
              still in their infancy (i.e., at very early stages). Such
              papers tend to provide research methodologies without yet
              concrete results.<br/>
              <Link to="/csce2025/paper_category_pst">Read More</Link> */}

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PapC;