import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PaperS extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Submission of Papers</h4>
            <div className="par">
            To submit your paper for Evaluation, upload to: <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a> <br />
            <br />
            {/* All accepted papers will be published by
PUBLISHER:  SPRINGER NATURE
Indexation: Scopus; DBLP, EI Engineering Index (Compendex, Inspec databases); Springer Link; Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others. Only about 5% of all journals and conference proceedings reach the same high level of scientific indexing as CSCE publications. */}
            {/* <br />
            <b>CSCE 2023 Proceedings (Last Year’s Xplore Indexation) can be found at:</b>
            <br /> */}
            {/* <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a>
            <br /> 
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            */}
            All accepted papers will be published by:<br />
            <table className="indextable">
            <tr>
                <th>PUBLISHER</th>
                <td>SPRINGER NATURE <a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">(https://www.springernature.com/)</a></td>
            </tr>
            <tr>
                <th>Indexation</th>
                <td>
                Scopus; DBLP, EI Engineering Index, Compendex, Inspec databases; Springer Link; Google 		Scholar; CPCI Citation Index, part of Clarivate Analytics' Web of Science; ACM Digital 			Library; IO-Port; MathSciNet; Zentralblatt MATH, and others. Only about 5% of all 			journals and conference proceedings reach the same high level of scientific indexing as 			CSCE publications.
                </td>
            </tr>
        </table>

         
            Each paper will be peer-reviewed by two experts in the field for originality, significance, clarity, impact, and soundness. In cases of contradictory recommendations, a member of the conference program committee would be charged to make the final decision; often, this would involve seeking help from additional referees. Papers whose authors include a member of the conference program committee will be evaluated using the double-blinded review process. (Essay/philosophical papers will not be refereed but may be considered for discussion/panels).
            <br /><br />
            For the submission of draft papers (ie, papers submitted for evaluation), ALL REASONABLE FORMATTING/TYPESETTING ARE ACCEPTABLE. Authors of ACCEPTED papers will later receive specific typesetting instructions to prepare their final versions. The final papers are expected to use the standard Springer Nature typesetting format available at: <br />
            <a href="https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines" target="_blank" rel="noopener noreferrer">https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines</a><br />
            Authors can find MS WORD and LaTex templates in the above link. 

            <br /><br />
            {/* Later, authors of accepted papers must agree with the standard 
            <a href="https://www.computer.org/conferences/cps/copyright" target="_blank" rel="noopener noreferrer"> IEEE CPS statement in reference to Copyrights (Intellectual Property Rights) </a>
            and 
            <a href="https://www.ieee.org/publications/rights/index.html" target="_blank" rel="noopener noreferrer"> Policy on Electronic Dissemination</a>
            <br /> <br /> */}
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at:<br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/ </a>
<br /><br />
            All submissions must be original and must not have been previously published or currently under consideration for publication elsewhere. For categories of papers and page limitations, refer to: <br />
            <a href="https://american-cse.org/csce2025/paper_categories" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/paper_categories</a>
            <br /><br />
            Submissions must be uploaded by the due date and must be in either PDF or MS WORD formats.
              <br/>
              <br/>
              The first page of the paper should include the followings: <br/>
              - Title of the paper <br/>
              - Name, affiliation, City, Country, and email address of each author (identify the name of the Contact Author). For Science Citation Indexation (SCI) inclusion processes, we prefer that the Contact Authors to provide email addresses that can be traced (ie, SCI requires that the Contact Authors be positively identified.)<br/>
              - Abstract (between 100 and 120 words)<br/>
              - A maximum of 6 topical keywords (Indexed Terms) that would best represent the work described in the paper (It would be more helpful to provide topical keywords that do not appear in the Title of the paper, if possible.)<br/>
              - After the topical keywords, Write the type of the submission as "Regular Research Paper", "Short Research Paper", or "Extended Abstract/Poster Research Paper". <br /><a href="https://american-cse.org/csce2025/paper_categories" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/paper_categories</a> <br/>
              - The actual text of the paper can start from the first page (space permitting).<br/>
              
              <br/><br/>
              A subset of past conference proceedings (publications) of CSCI and CSCE appear in the links below (there are also multiple books in the publication pipeline): <br />
              <b>Past Publications of Proceedings/Books can be found at:</b><br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>  <br />
            <a href="https://american-cse.org/csce2025/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/special_issues</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings</a><br />  
            
            </div>

            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PaperS;