import React, { Component } from 'react';
import { Form, Button,Col, Row, Modal, ListGroup, Tabs, Tab, Table  } from 'react-bootstrap';
import country_list from './_countries'
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import {alertMess} from '../../actions';

class Reg_Fee_C extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paperCount: 0,
        }
    }

    componentWillMount = () => {
        try {
            let st = this.props.location.state.form
        } catch {
            this.props.replace("/csce2025/registration_start")
        }
    }

    onNext1 = () => {
        this.props.alertMess({isVis: false, message:'', error:false});
        let isStudent = 0
        let isAuthor = 0
        let pc = 0

        try {
            isStudent = parseInt(document.querySelector('input[name="formHorizontalRadios"]:checked').value);
        } catch(err) {
            this.props.alertMess({isVis: true, message:'Please fill the form completely', error:true});
            return;
        }
        try {
            isAuthor = parseInt(document.querySelector('input[name="formHorizontalRadios2"]:checked').value);
        } catch(err) {
            this.props.alertMess({isVis: true, message:'Please fill the form completely', error:true});
            return;
        }
        try {
            pc = parseInt(document.querySelector('input[name="formHorizontalRadios3"]:checked').value);
        } catch(err) {
            this.props.alertMess({isVis: true, message:'Please fill the form completely', error:true});
            return;
        }


        let p1_id = "-"
        let p1_title = "-"
        let p2_id = "-"
        let p2_title = "-"
        let p3_id = "-"
        let p3_title = "-"

        if(pc >= 1){
            p1_id = document.getElementById("pap1_id").value
            p1_title = document.getElementById("pap1_title").value
            if(p1_id == "" || p1_title == ""){
                this.props.alertMess({isVis: true, message:'Please fill the form completely', error:true});
                return;
            }
        }
        if(pc >= 2){
            p2_id = document.getElementById("pap2_id").value
            p2_title = document.getElementById("pap2_title").value
            if(p2_id == "" || p2_title == ""){
                this.props.alertMess({isVis: true, message:'Please fill the form completely', error:true});
                return;
            }
        }
        if(pc >= 3){
            p3_id = document.getElementById("pap3_id").value
            p3_title = document.getElementById("pap3_title").value
            if(p3_id == "" || p3_title == ""){
                this.props.alertMess({isVis: true, message:'Please fill the form completely', error:true});
                return;
            }
        }

        let obj = {
            ...this.props.location.state.form,
            isStudent: isStudent,
            isAuthor: isAuthor,
            pc: pc,
            p1_id: p1_id,
            p2_id: p2_id,
            p3_id: p3_id,
            p1_title: p1_title,
            p2_title: p2_title,
            p3_title: p3_title,
        }
        
        // if(obj.ln == "!!ARABNIA!!"){
        //     this.props.replace(
        //         {
        //             pathname: '/csce2025/registration_card_arabnia',
        //             state: { form: obj }
        //         })
        // }else{
            this.props.replace(
                {
                    pathname: '/csce2025/registration_card',
                    state: { form: obj }
                })
            // alert("We are testing our merchant at the moment. Please try again later.")
        // }
        
    }

    render() {
    return (
        <div className="r_container">
        <div className="home">
            <div className="new_banner">
                <div className="banner_content">
                <h1>CSCE 2025</h1>
                <h6>The 2025 World Congress in</h6>
                <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
                <h6>July 21-24, 2025, Las Vegas, USA</h6>
                </div>

                <div className="new_banner_overlay"></div>
            </div>

            <div className="page_content">
                <h6 className="par_header">Registration - Conference Info</h6>
                <div className="reg_form">
                
                <Form>
                    
                    <Row>
                        <Col sm={7}>
                            <Form.Label>
                                Are you a Full Time student?*
                            </Form.Label>
                        </Col>
                        <Col sm={5}>
                            <Form.Group id="ft_st">
                                <Form.Check
                                type="radio"
                                label="Yes ($575)"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios1"
                                value="1"
                                inline
                                />
                                <Form.Check
                                type="radio"
                                label="No ($675)"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios2"
                                value="0"
                                inline
                                />
                            </Form.Group>
                        </Col>
                        <p className="p_moreInfo">
                            Student rate attendees must have a letter from their Department Head/Chair that states that the attendee is a full time student. This letter is required to pick up the registration materials on site in Las Vegas.
                        </p>
                    </Row>

                    <hr/>

                    <Row>
                        <Col sm={7}>
                            <Form.Label>
                                Are you registering as an Author?*
                            </Form.Label>
                        </Col>
                        <Col sm={5}>
                            <Form.Group id="is_aut">
                                <Form.Check
                                type="radio"
                                label="Yes"
                                name="formHorizontalRadios2"
                                id="formHorizontalRadios3"
                                value="1"
                                inline
                                />
                                <Form.Check
                                type="radio"
                                label="No"
                                name="formHorizontalRadios2"
                                id="formHorizontalRadios4"
                                value="0"
                                inline
                                />
                            </Form.Group>
                        </Col>
                        <p className="p_moreInfo">
                        If you answered yes, please indicate in the table below the Paper I.D. # (a 4-letter acronym followed by a 4-digit number) and Title for each paper. Your paper I.D. Number can be found in the notification of acceptance emails. Non-authors should leave it blank.
                        </p>
                    </Row>

                    <hr/>

                    <Row>
                        <Col sm={7}>
                            <Form.Label>
                                Total number of papers covered with this registration*
                            </Form.Label>
                        </Col>
                        <Col sm={5}>
                            <Form.Group id="paper_c" onChange={(e)=> {
                                this.setState({
                                    paperCount: parseInt(e.target.value)
                                })
                            }}>
                                <Form.Check
                                type="radio"
                                label="0"
                                name="formHorizontalRadios3"
                                id="formHorizontalRadios5"
                                value="0"
                                inline
                                />
                                <Form.Check
                                type="radio"
                                label="1"
                                name="formHorizontalRadios3"
                                id="formHorizontalRadios6"
                                value="1"
                                inline
                                />
                                <Form.Check
                                type="radio"
                                label="2"
                                name="formHorizontalRadios3"
                                id="formHorizontalRadios7"
                                value="2"
                                inline
                                />
                                <Form.Check
                                type="radio"
                                label="3"
                                name="formHorizontalRadios3"
                                id="formHorizontalRadios8"
                                value="3"
                                inline
                                />
                            </Form.Group>
                        </Col>
                        <p className="p_moreInfo">
                        Authors with more than one paper must pay an "Additional Publication Charge" of $495.00 for each additional paper.
                        </p>
                    </Row>
                    
                    <hr/>
                    
                    {this.state.paperCount >= 1 &&
                    <Row>
                        <Col sm={2}>
                            <Form.Label>Paper1: </Form.Label>
                        </Col>
                        <Col sm={3}>
                            <Form.Control id="pap1_id" placeholder="Paper ID" />
                        </Col>
                        <Col sm={7}>
                            <Form.Control id="pap1_title" placeholder="Paper Title" />
                        </Col>
                    </Row>
                    }

                    {this.state.paperCount >= 2 &&
                    <Row>
                         <Col sm={2}>
                            <Form.Label>Paper2: </Form.Label>
                        </Col>
                        <Col sm={3}>
                            <Form.Control id="pap2_id" placeholder="Paper ID" />
                        </Col>
                        <Col sm={7}>
                            <Form.Control id="pap2_title" placeholder="Paper Title" />
                        </Col>
                    </Row>
                    }
                    
                    {this.state.paperCount >= 3 &&
                    <Row>
                        <Col sm={2}>
                            <Form.Label>Paper3: </Form.Label>
                        </Col>
                        <Col sm={3}>
                            <Form.Control id="pap3_id" placeholder="Paper ID" />
                        </Col>
                        <Col sm={7}>
                            <Form.Control id="pap3_title" placeholder="Paper Title" />
                        </Col>
                    </Row>
                    }

                </Form>
              </div>
              <div>
                <b>Terms and Conditions</b>  <br/><br/>
                
                <b>- Refund Policy:</b> Paid NON-AUTHOR registrants who cannot participate and do not arrange a substitute, are entitled to a partial refund of $200.00 if a request is received in writing on or before July 7, 2025. Registrants are liable for their full fees after that date (i.e., NO Refund will be made). There will be NO REFUNDS to authors of papers (since authors' fees are used to cover the publication, preparation of indexation meta-data, organization, infrastructure, ... costs of authors' own papers.)"
                <br/>
                <b>- For those who are physically attending the conference: </b>
                Registration Fee will include the conference proceedings, printed Conference Program (a conference package); conference reception dinner; refreshment breaks (coffee/tea, etc.); access to all sessions, panel discussions, keynotes/Invited lectures; miscellaneous conference materials.
                <br/>
                <b>- For those who are NOT physically attending the conference (ie, virtual participation): </b>
                Registration Fee will include the conference proceedings (publication of accepted paper(s)); access to Conference Program; access to sessions; dissemination of their presentation and published paper (in case of speakers, authors), ...
                <br/>
                <b>- Authors of papers</b> must register and must upload their correctly typeset papers to the publication web site by the announced deadlines, if they wish for their papers to be published.
                <br/>
                <b>- All Payments</b> must be in U.S. Dollars. All checks from banks outside the United States should be cashable at a branch of that bank in the United States or at any US bank.
                <br/>
                <b>- Authors with more than one paper</b> must pay an "Extra Publication Charge" of $495.00 for each additional paper.
                <br/>
                <b>- Student rate</b> attendees must have a letter from their Department Head/Chair that states that the attendee is a full time student. This letter is required to pick up the registration materials.
                
              </div>
            </div>
            <div style={{"text-align": "center", margin: "0 auto"}}>
                <Button style={{margin: "0.5rem"}} onClick={this.onNext1}>
                    I Agree
                </Button>
                <Button style={{margin: "0.5rem",backgroundColor: "red", borderColor: "white"}} onClick={()=>{
                    this.props.push("/csce2025/registration_start")
                }}>
                    I Don't Agree
                </Button>
            </div>
        
        
        </div>
        </div>

    );
  }
}

const mapStateToProps = state => {
    return {
        alertReducer: state.alertReducer,
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      alertMess,
      replace
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps())(Reg_Fee_C);