import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote5 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>What Can we Learn from Student Course-Grade Data?</b> <br/>
            Prof. Gary Weiss <br/>
            Professor, Computer & Information Science Department, Fordham University, USA
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            Data science has made great inroads into many areas of our daily life, plays a key role in many
industries, and is beginning to play an increasing role in education. In this talk I will describe the
variety of knowledge that can be gleaned by analyzing undergraduate course-grade data (i.e.,
data that list all courses a student took and their corresponding grade). I will show how this data
can be used to: identify strong and weak instructors and easy and hard graders; characterize
grading patterns at the student, course, instructor, and department level; characterize how
students sequence their courses and the impact of this sequencing on student learning; identify
majors in which a student will perform well; and group courses based on student course co-
enrollments or similar patterns in student performance.
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Dr. Gary M. Weiss is a Professor of Computer and Information Science at Fordham University.
He received his B.S. degree in Computer Science from Cornell University, M.S. degree in
Computer Science from Stanford University, and doctorate in Computer Science from Rutgers
University. He joined Fordham in 2004 after working for nearly two decades at AT&T Bell Labs.
His main research area is data mining and machine learning. Since 2019 he has been working
with two of his colleagues in the area of educational data mining, with the goal of improving
higher education with a data-intensive approach. Dr. Weiss has published over ninety papers and
book chapters in the area of data science. He is currently Co-PI on a $4M US National Science
Foundation scholarship grant to educate the next generation of cybersecurity practitioners by
incorporating data science into the cybersecurity curriculum and is the director of Fordham’s MS
in Computer Science program.
            <br/> <br/>
            
            </div>
            <br /> <br />
            <div style={{textAlign: "center"}}>
                <img src="/static/weiss2.png" height="300px" alt=""/>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote5;