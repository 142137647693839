import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsICEQT extends Component {

  componentWillMount = () => {
    // console.log(this.props)
    window.location.assign('https://qmexico.org/ICEQT2025/');
}


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ICEQT 2025</h4>
            <div className="par">
            
            </div>
                        
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsICEQT;