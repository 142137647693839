import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote2 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>Artificial Intelligence and Chatbots</b> <br/>
            Dr. Jim Crowder <br/>
            Systems Fellow, CAES Advanced Program Development, USA <br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            There has been much talk over the last few years about the perils of the use of artificial intelligence in virtually everything we touch.  From our phones to our cars, and everything in between, artificial intelligence is an integral part of our existence.  Many prominent people, like Elon Musk and Stephen Hawking, have warned about the potential for machines to take over and cause havoc in the lives and very existence of humans.  Hollywood has made untold billions of dollars painting doom-and-gloom scenarios about artificial intelligence and robots within our society today and in the future.   But what is the true reality?  We continually push to create increasingly intelligent online entities (Chatbots) that seemingly learn, think, and reason like humans.  Most attempts fail miserably.  Given the recent “issues” with AI Chatbots like ChatGPTÒ we enter a brave new world of interactions with AI-like entities.  These AI Chatbots have little real cognitive functions and do not understand human emotions.  Here we will discuss the issues associated with the proliferation of online Chatbots and their effects on society and human relations, both good and bad.
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Dr. Jim Crowder is an Engineering Fellow at Cobham Advanced Electronic Solutions (CAES) Advanced Program Development (APD), formerly Colorado Engineering, Inc. Dr. Crowder’s career spans over 37 years of experience in engineering, 30 of which has been in artificial intelligence. Having fielded his first Neural Network system in 1993 and his first Fuzzy system in 1995, advanced computing and artificial intelligence has been his focus across Lockheed Martin, Northrup Grumman, Raytheon Technologies, and now an engineering firm in Colorado Springs, CAES APD. Dr. Crowder holds a PhD in Electrical Engineering, 2 Masters Degrees (one in Electrical Engineering and one in Applied Math) and a BS in Electrical Engineering. He has designed and fielded multiple artificial intelligence systems for most of the Department of Defense agencies, including DARPA, Air Force Research Labs, the Navy, and the Army. He has published 8 books on Systems Engineering and Artificial Intelligence through Springer, which are currently used by Southern Methodist University, Texas A&M, and Baylor University. Dr. Crowder has over 120 journal and conference publications and continues to be a leader in the field of Artificial Intelligence, Evolutionary Algorithms, Fuzzy Systems, Cognitive Architectures and Machine Learning. His ground-breaking work in Artificial Psychology (the Psychology of AI) has been downloaded and read over 17,000 times and continues to be a major download from ResearchGate with close to 100 downloads weekly. He has begun work on his next textbook on Artificial Emotions and Ethics for AI Systems.  Dr. Crowder is also on contract to publish a short book titled “Chatbots: The Good, The Bad, and the Ugly,” which is the topic of this keynote address.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/crowder2.png" height="300px" alt=""/>
            </div>
            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote2;