import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Symps extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h6 className="par_header">CSCE'25 Conferences</h6>
            <div className="par">
            In order to leverage the synergies between various CS & CE fields, the program committee of the following conferences have their 2025 events held at one venue (same location and dates). Thus, this year, The Congress is composed of a number of tracks (joint-conferences, tutorials, sessions, workshops, poster and panel discussions); all will be held simultaneously, same location and dates: July 21-24, 2025. For the complete list of joint conferences, see below:
            <br/><br/>
              <div className="sym_links">
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ACC">The 9th International Conference on Applied Cognitive Computing (ACC'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-BIOCOMP">The 26th International Conference on Bioinformatics & Computational Biology (BIOCOMP'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-BIOENG">The 11th International Conference on Biomedical Engineering & Sciences (BIOENG'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-CSC">The 23rd International Conference on Scientific Computing (CSC'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-EEE">The 24th International Conference on e-Learning, e-Business, Enterprise Information Systems, & e-Government (EEE'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ESCS">The 23rd International Conference on Embedded Systems, Cyber-physical Systems, & Applications (ESCS'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-FCS">The 21st International Conference on Foundations of Computer Science (FCS'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-FECS">The 21st International Conference on Frontiers in Education: Computer Science & Computer Engineering (FECS'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-GCC">The 21st International Conference on Grid, Cloud, & Cluster Computing (GCC'25)</Link><br/>
                <Icon icon='link' size="1x"/> <a  href="https://www.himscsce.org/hims25" target="_blank" rel="noopener noreferrer">The 11th International Conference on Health Informatics & Medical Systems (HIMS'25)</a> <br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ICAI">The 27th International Conference on Artificial Intelligence (ICAI'25)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="https://icdata.org" target="_blank" rel="noopener noreferrer">The 21st International Conference on Data Science (ICDATA'25)</a> <br/>
                <Icon icon='link' size="1x"/> <a href="https://qmexico.org/ICEQT2025/" target="_blank" rel="noopener noreferrer">The 4th International Conference on Emergent Quantum Technologies (ICEQT'25)</a> <br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ICOMP">The 26th International Conference on Internet Computing & IoT (ICOMP'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-ICWN">The 24th International Conference on Wireless Networks (ICWN'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-IKE">The 24th International Conference on Information & Knowledge Engineering (IKE'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-IPCV">The 29th International Conference on Image Processing, Computer Vision, & Pattern Recognition (IPCV'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-MSV">The 22nd International Conference on Modeling, Simulation & Visualization Methods (MSV'25)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-PDPTA">The 31st International Conference on Parallel & Distributed Processing Techniques & Applications (PDPTA'25)</Link><br/>
                <Icon icon='link' size="1x"/> <a href="http://sam.udmercy.edu/sam25/" target="_blank" rel="noopener noreferrer">The 24th International Conference on Security & Management (SAM'25)</a><br/>
                <Icon icon='link' size="1x"/> <Link to="/csce2025/conferences-SERP">The 23rd International Conference on Software Engineering Research & Practice (SERP'25)</Link><br/>                
              </div>
              <br/>
              This year’s Congress will be a hybrid event that combines a “live” in-person event with a “virtual” online component. We anticipate that most speakers will physically be attending (ie, most talks are expected to be face-to-face).
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Symps;