import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsICDATA extends Component {

    componentWillMount = () => {
        // console.log(this.props)
        window.location.assign('https://icdata.org');
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ICDATA'25 - The 21st International Conference on Data Science</h4>
            <div className="par">
            ICDATA is an international conference that serves researchers, scholars, professionals,
            students, and academicians who are looking to both foster working relationships and gain
            access to the latest research results. It is being held jointly (same location and dates)
            with a number of other research conferences; namely, The 2025 World Congress in Computer
            Science, Computer Engineering, and Applied Computing (CSCE'25).
            <br/><br/>
            We anticipate having between 1,000 and 1,500 participants in the Congress. The congress
            includes 21 major tracks composed of: 112 technical, research, and panel sessions as well
            as a number of keynote lectures and tutorials; all will be held simultaneously, same
            location and dates: July 21-24, 2025. Last year, the Congress had attracted speakers/authors
            and participants affiliated with over 148 different universities (including many from the
            top 50 ranked institutions), major IT corporations (including: Microsoft, Google, Apple,
            SAP, Facebook, Oracle, Amazon, Yahoo, Samsung, IBM, Ebay, GE, Siemens, Philips, Ericsson,
            BAE Systems, Hitachi, NTT, Twitter, Uber Technologies, ...), major corporations (including:
            Exxon Mobil, Johnson & Johnson, JPMorgan Chase, PetroChina, GlaxoSmithKline, HSBC, Airbus,
            Boeing, Hyundai, Goldman Sachs, Deutsche Bank, ...), government research agencies (NSF, NIH,
            DoE, US Air Force, NSA National Security Agency, Central Intelligence Agency, ...), US
            national laboratories (including, NASA National Aeronautics and Space Administration, ANL
            Argonne National Lab, Sandia National Lab, ORNL Oak Ridge National Lab, Lawrence Berkeley
            National Lab, Lawrence Livermore National Lab, Los Alamos National Lab, Pacific Northwest
            National Lab, ...), and a number of Venture Capitalists as well as distinguished speakers
            discussing Intellectual Property issues. Last year, 54% of attendees were from academia,
            25% from industry; 20% from government and funding agencies; and 1% unknown. About half of
            the attendees were from outside USA; from 69 nations.
            <br/><br/>
            Data Science is an inter-disciplinary field that utilizes scientific methods, processes,
            algorithms, software tools, and systems to extract knowledge and insights from many
            structural and unstructured data. Data Science is a superset of Data Mining, Machine
              Learning, Big Data, Statistics, and Artificial Intelligence.
            <br/><br/>
            The primary goal of this conference is to provide a platform for researchers, scientists,
            industry experts and scholars to share their novel ideas and research results in the
            area of Data Science.
            <br/><br/>
            <b>You are invited to submit a paper for consideration.</b> All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings (Research Books) and indexed in major Science Citation Indexation databases (including: Scopus; DBLP; EI Engineering Index; Compendex; Inspec databases; Springer Links; Google Scholar; CP Citation Index, CPCI; part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.)
            <br/>
            <br />
            <b>PUBLISHER:</b> <br />
            Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)
            {/* <br />
            Papers should be typeset by using the general typesetting instructions available at (select "US letter" option for accessing templates):
            <br />
            <a href="https://www.ieee.org/conferences/publishing/templates.html " target="_blank" rel="noopener noreferrer">https://www.ieee.org/conferences/publishing/templates.html</a> <br />
            (i.e., single line spacing, 2-column format). All submissions must be original (papers must not have been previously published or currently being considered by others for publication). */}

            <br /><br />
            <b>Past Publications of Proceedings/Books can be found at:</b><br />
            <a href="https://link.springer.com/conference/csce-1" target="_blank" rel="noopener noreferrer">https://link.springer.com/conference/csce-1</a><br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85638-9" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85638-9</a>  <br />
            <a href="https://link.springer.com/book/10.1007/978-3-031-85884-0" target="_blank" rel="noopener noreferrer">https://link.springer.com/book/10.1007/978-3-031-85884-0</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344126/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344126/html</a>  <br />
            <a href="https://www.degruyter.com/document/doi/10.1515/9783111344553/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/document/doi/10.1515/9783111344553/html</a>  <br />
            <a href="https://www.degruyter.com/serial/ic-b/html" target="_blank" rel="noopener noreferrer">https://www.degruyter.com/serial/ic-b/html</a>  <br />
            <a href="https://american-cse.org/csce2025/special_issues" target="_blank" rel="noopener noreferrer">https://american-cse.org/csce2025/special_issues</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/10487132/proceeding</a> <br /> 
            <a href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings" target="_blank" rel="noopener noreferrer">https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings</a><br />  
            <br /> 
            Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <br />
            <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a>
            <br/><br/>
            All researchers, authors and scientists are respectfully encouraged to contribute to the
          conference with submissions of their novel research papers having unpublished and original
          research with theoretical, mathematical, empirical and experimental work in the areas
          listed below. <br/>
            <br/>
            SCOPE: TOPICS OF INTEREST INCLUDE, BUT ARE NOT LIMITED TO, THE FOLLOWING: <br/>
            <b>DATA MINING/MACHINE LEARNING TASKS:</b> <br/>
            - Regression/Classification <br/>
            - Time series forecasting<br/>
            - Segmentation/Clustering/Association<br/>
            - Deviation and outlier detection<br/>
            - Explorative and visual data mining<br/>
            - Web mining<br/>
            - Mining text and semi-structured data<br/>
            - Temporal and spatial data mining<br/>
            - Multimedia mining (audio/video)<br/>
            - Mining "Big Data"<br/>
            - and related topics<br/>
            <br/>
            <b>DATA MINING ALGORITHMS:</b><br/>
            - Artificial neural networks, Deep Learning<br/>
            - Fuzzy logic and rough sets<br/>
            - Decision trees/rule learners<br/>
            - Support vector machines<br/>
            - Evolutionary computation/meta heuristics<br/>
            - Statistical methods<br/>
            - Collaborative filtering<br/>
            - Case based reasoning<br/>
            - Link and sequence analysis<br/>
            - Ensembles/committee approaches<br/>
            - and related topics<br/>
            <br/>
            <b>DATA MINING INTEGRATION:</b><br/>
            - Mining large scale data/big data<br/>
            - Data and knowledge representation<br/>
            - Data warehousing and OLAP integration<br/>
            - Integration of prior domain knowledge<br/>
            - Metadata and ontologies<br/>
            - Agent technolog ies for data mining<br/>
            - Legal and social aspects of data mining<br/>
            <br/>
            <b>DATA MINING PROCESS:</b><br/>
            - Data cleaning and preparation<br/>
            - Feature selection and transformation<br/>
            - Attribute discretisation and encoding<br/>
            - Sampling and rebalancing<br/>
            - Missing value imputation<br/>
            - Model selection/assessment and comparison<br/>
            - Induction principles<br/>
            - Model interpretation<br/>
            - and related topics<br/>
            <br/>
            <b>DATA MINING APPLICATIONS:</b><br/>
            - Bioinformatics<br/>
            - Medicine Data Mining<br/>
            - Business / Corporate / Industrial Data Mining<br/>
            - Credit Scoring<br/>
            - Direct Marketing<br/>
            - Database Marketing<br/>
            - Engineering Mining<br/>
            - Military Data Mining<br/>
            - Security Data Mining<br/>
            - Social Science Mining<br/>
            - Data Mining in Logistics<br/>
            - and related topics<br/>
            <br/>
            <b>DATA MINING SOFTWARE:</b><br/>
            - All aspects of data mining software, including: modules, frameworks, ...<br/>
            <br/>
            <b>ALGORITHMS FOR BIG DATA:</b><br/>
            - Data and Information Fusion<br/>
            - Algorithms (including Scalable methods)<br/>
            - Natural Language Processing<br/>
            - Signal Processing<br/>
            - Simulation and Modeling<br/>
            - Data-Intensive Computing<br/>
            - Parallel Algorithms<br/>
            - Testing Methods<br/>
            - Multidimensional Big Data<br/>
            - Multilinear Subspace Learning<br/>
            - Sampling Methodologies<br/>
            - Streaming<br/>
            - and related topics<br/>
            <br/>
            <b>BIG DATA FUNDAMENTALS:</b><br/>
            - Novel Computational Methodologies<br/>
            - Algorithms for Enhancing Data Quality<br/>
            - Models and Frameworks for Big Data<br/>
            - Graph Algorithms and Big Data<br/>
            - Computational Science<br/>
            - Computational Intelligence<br/>
            - and related topics<br/>
            <br/>
            <b> INFRASTRUCTURES FOR BIG DATA:</b><br/>
            - Cloud Based Infrastructures (applications, storage, ...)<br/>
            - Grid and Stream Computing for Big Data<br/>
            - High Performance Computing<br/>
            - Autonomic Computing<br/>
            - Cyber-infrastructures and System Architectures<br/>
            - Programming Models and Environments to Support Big Data<br/>
            - Software and Tools for Big Data<br/>
            - Big Data Open Platforms<br/>
            - Emerging Architectural Frameworks for Big Data<br/>
            - Paradigms and Models for Big Data beyond Hadoop/MapReduce<br/>
            - and related topics<br/>


            <br/>
            <b> BIG DATA MANAGEMENT AND FRAMEWORKS:</b><br/>
            - Database and Web Applications<br/>
            - Federated Database Systems<br/>
            - Distributed Database Systems<br/>
            - Distributed File Systems<br/>
            - Distributed Storage Systems<br/>
            - Knowledge Management and Engineering<br/>
            - Massively Parallel Processing (MPP) Databases<br/>
            - Novel Data Models<br/>
            - Data Preservation and Provenance<br/>
            - Data Protection Methods<br/>
            - Data Integrity and Privacy Standards and Policies<br/>
            - Data Science<br/>
            - Novel Data Management Methods<br/>
            - Crowdsourcing<br/>
            - Stream Data Management<br/>
            - Scientific Data Management<br/>
            - and related topics<br/>
            <br/>
            <b> BIG DATA SEARCH:</b><br/>
            - Multimedia and Big Data<br/>
            - Social Networks<br/>
            - Data Science<br/>
            - Web Search and Information Extraction<br/>
            - Scalable Search Architectures<br/>
            - Cleaning Big Data (noise reduction), Acquisition & Integration<br/>
            - Visualization Methods for Search<br/>
            - Time Series Analysis<br/>
            - Recommendation Systems<br/>
            - Graph Based Search and Similar Technologies<br/>
            - and related topics<br/>
            <br/>
            <b>PRIVACY IN THE ERA OF BIG DATA:</b><br/>
            - Cryptography<br/>
            - Threat Detection Using Big Data Analytics<br/>
            - Privacy Threats of Big Data<br/>
            - Privacy Preserving Big Data Collection<br/>
            - Intrusion Detection<br/>
            - Socio-economical Aspect of Big Data in the Context of<br/>
              Privacy and Security<br/>
            - and related topics<br/>
            <br/>
            <b> APPLICATIONS OF BIG DATA:</b><br/>
            - Big Data as a Service<br/>
            - Big Data Analytics in e-Government and Society<br/>
            - Applications in Science, Engineering, Healthcare, Visualization,
              Business, Education, Security, Humanities, Bioinformatics,
              Health Informatics, Medicine, Finance, Law, Transportation,
              Retailing, Telecommunication, all Search-based applications, ...<br/>
            - and related topics<br/>
            <br/><br/>
            ICDATA Program Committee particularly encourages submissions of
            industrial applications and case studies from practitioners. These
            will not be evaluated using solely theoretical research criteria, but
            will take general interest and presentation into consideration.
            
            
            
          </div>
          
          </div>
        </div>
      </div>
    );
  }
}

export default SympsICDATA;