import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Accordion, Card  } from 'react-bootstrap';

class Approved_Sesss1 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Special Session on Blockchain Technology and Applications (BTA 2025)</h4>
            <div className="par">
                <b>In association with <a href="https://www.american-cse.org/csce2025/conferences-PDPTA" target="_blank" rel="noopener noreferrer">PDPTA'25- The 28th Int'l Conf on Parallel and Distributed Processing Techniques and Applications</a></b>            

                <br/>
                <h6 className="par_header">Track Chairs</h6>
                <div className="par">
                    <b>Dr. Amjad Gawanmeh</b> -  amjad.gawanmeh@ieee.org <br/>
                    <b>Dr. Kashif Saleem</b> -  ksaleem@ksu.edu.sa & kashnet@gmail.com
                </div>

                <h6 className="par_header">Description</h6>
                <div className="par">
                Blockchain is gradually becoming a mainstream technology with a huge potential for growth, adoption, and impact. Blockchain is new paradigm with the ability to transform technology with several new and revolutionary applications across many areas. Blockchain technology can provide instant transactions as well as data transfer and access within a trusted, secure, transparent, tamper-proof, and decentralized manner with no third party servers or trusted gents. Since the technology has been adopted in several areas, new key challenges have arisen. 
                <br/> <br/>
                This track aims to bring together academia and industry for discussing the latest advances on the following themes: <br/>
                -	Design and engineering improvements to the protocols and architectures underpinning Blockchain <br/>
                -	Convergence of Blockchain with other emerging technologies (e.g., Artificial Intelligence, Internet of Things) <br/>
                -	Insights of the technical, business, and societal implications of Blockchains based on deployed applications and case studies <br/>
                </div>

                <h6 className="par_header">Topics of Interest</h6>
                <div className="par">
                We welcome submissions of papers presenting original work in the following major areas: <br/> <br/>
                -	Applications for smart cities <br/>
                -	Auditability and traceability <br/>
                -	Blockchain in AI, Machine Learning, cyber security, Identity and Access Management (IAM) <br/>
                -	Blockchain tools, simulators, and test-nets <br/>
                -	Blockchain-based IoT, fog, cloud, and mobile devices <br/>
                -	Blockchain in cyber-physical systems (CPS) <br/>
                -	Building private blockchain systems <br/>
                -	Consensus mechanisms <br/>
                -	Cross-blockchain ecosystems <br/>
                -	Decentralized applications <br/>
                -	Decentralized financing and payments <br/>
                -	Decentralized storage and InterPlanetary File System (IPFS) <br/>
                -	Distributed storage in blockchain <br/>
                -	Trust and reputation management <br/>
                -	Underlying infrastructure for blockchain systems <br/>
                -	Interoperability of blockchains <br/>
                -	On-chain and off-chain applications <br/>
                -	Privacy and security of ledgers <br/>
                -	Performance and Scalability <br/>
                -	Secure contracts <br/>
                -	Side chains and channels <br/>
                -	Smart contracts <br/>
                -	Trusted Execution Environment <br/>
                -	Zero knowledge proof
                </div>
                <h6 className="par_header">Resources</h6>
                <div className="par">
                    <b>Submission Link: </b> <a href="https://edas.info/newPaper.php?c=28437" target="_blank" rel="noopener noreferrer">https://edas.info/newPaper.php?c=28437</a> <br/>
                    <b>Submission Instructions: </b> <a href="https://www.american-cse.org/csce2025/paper_submission" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csce2025/paper_submission</a>
                </div>
            </div>
          </div>
          

        </div>
      </div>
    );
  }
}

export default Approved_Sesss1;