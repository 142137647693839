import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Router, Route, IndexRoute, browserHistory, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import { syncHistoryWithStore, routerReducer, routerMiddleware, push } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from './components/App';
import Home from './components/home/Home';
import Symps from './components/Symps/Symps';
import SympsACC from './components/Symps/ind_symp/ACC';
import SympsBIOCOMP from './components/Symps/ind_symp/BIOCOMP';
import SympsBIOENG from './components/Symps/ind_symp/BIOENG';
import SympsCSC from './components/Symps/ind_symp/CSC';
import SympsEEE from './components/Symps/ind_symp/EEE';
import SympsESCS from './components/Symps/ind_symp/ESCS';
import SympsFCS from './components/Symps/ind_symp/FCS';
import SympsFECS from './components/Symps/ind_symp/FECS';
import SympsGCC from './components/Symps/ind_symp/GCC';
import SympsHIMS from './components/Symps/ind_symp/HIMS';
import SympsICAI from './components/Symps/ind_symp/ICAI';
import SympsICDATA from './components/Symps/ind_symp/ICDATA';
import SympsICOMP from './components/Symps/ind_symp/ICOMP';
import SympsICWN from './components/Symps/ind_symp/ICWN';
import SympsIKE from './components/Symps/ind_symp/IKE';
import SympsIPCV from './components/Symps/ind_symp/IPCV';
import SympsMSV from './components/Symps/ind_symp/MSV';
import SympsPDPTA from './components/Symps/ind_symp/PDPTA';
import SympsSAM from './components/Symps/ind_symp/SAM';
import SympsSERP from './components/Symps/ind_symp/SERP';
import SympsICEQT from './components/Symps/ind_symp/ICEQT';
import Demo from './components/Demo';
import Pub from './components/Publisher';
import Schedules from './components/Schedules';
import PaperS from './components/Paper_sub';
import PapC from './components/Paper_Cat';
import Contact from './components/Contact'
import Scope from './components/Scope';
import Review from './components/Review'
import Deadlines from './components/Deadlines'
import Org from './components/Org'
import Venue from './components/Venue'
import Reg from './components/Reg'
import Authors from './components/Authors'
import PapC_2 from './components/Paper_Cat_2';
import PapC_4 from './components/Paper_Cat_4';
import PapC_6 from './components/Paper_Cat_6';
import PresMs from './components/Pres_Modes';
import Jobs from './components/Jobs';
import Reg_Form from './components/Reg_Pages/Reg_Form'
import Reg_Form2 from './components/Reg_Pages/Reg_Form2'
import Reg_start from './components/Reg_Pages/Reg_Start'
import Reg_Fee_C from './components/Reg_Pages/Reg_Fee_C'
import Reg_Fee_C2 from './components/Reg_Pages/Reg_Fee_C2'
import Reg_Card from './components/Reg_Pages/Reg_Card'
import Reg_Card2 from './components/Reg_Pages/Reg_Card2'
import Reg_Card_Cust from './components/Reg_Pages/Reg_Card_Cust'
import Reg_Card_Arabnia from './components/Reg_Pages/Reg_Card_Arabnia'
import Reg_done from './components/Reg_Pages/Reg_Done'
import Reg_done_cust from './components/Reg_Pages/Reg_Done_Cust'
import Photos from './components/Photos'
import Speakers from './components/Speakers'
import Sesh_P from './components/Sess_P'
import Spec_issue from './components/Spec_issue'
import Keynotes from './components/Keynotes'
import Approved_Sesss from './components/Approved_Sesss'
import Approved_Sesss1 from './components/app_sess_1'
import Approved_Sesss2 from './components/app_sess_2'
import Approved_Sesss3 from './components/app_sess_3'
import Visa_start from './components/Visa/Visa_Start'
import Visa_Form from './components/Visa/Visa_Form'
import Visa_done from "./components/Visa/Visa_Done"
import Keynote1 from './components/keynote1'
import Keynote2 from './components/keynote2'
import Keynote3 from './components/keynote3'
import Keynote4 from './components/keynote4'
import Keynote5 from './components/keynote5'
import Keynote6 from './components/keynote6'
import Keynote7 from './components/keynote7'
import Keynote8 from './components/keynote8'
// import Keynote9 from './components/keynote9'
import Keynote10 from './components/keynote10'
import PresSch from './components/Pres_Sched'
import Committees from './components/Committees'
import Keynote12 from './components/keynote12'
import Proceedings from './components/proceedings'
// import FinalPapers from './components/FinalPapers'

import reducers from './reducers';
import './components/bundle.scss';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import Sess_P from './components/Sess_P';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['routing', 'Spinner_ActionReducer', 'alertReducer']
}

const persistedReducer = persistReducer(persistConfig, reducers)
const routermiddleware = routerMiddleware(browserHistory)

const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(thunk, routermiddleware),
));

const history = syncHistoryWithStore(browserHistory, store)
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <Route path="/" component={App}>
            <IndexRoute component={Home} />
            <Route path="/csce2025/" component={Home} />
            <Route path="/csce2025/conferences-ACC" component={SympsACC} />
            <Route path="/csce2025/conferences-BIOCOMP" component={SympsBIOCOMP} />
            <Route path="/csce2025/conferences-BIOENG" component={SympsBIOENG} />
            <Route path="/csce2025/conferences-CSC" component={SympsCSC} />
            <Route path="/csce2025/conferences-EEE" component={SympsEEE} />
            <Route path="/csce2025/conferences-ESCS" component={SympsESCS} />
            <Route path="/csce2025/conferences-FCS" component={SympsFCS} />
            <Route path="/csce2025/conferences-FECS" component={SympsFECS} />
            <Route path="/csce2025/conferences-GCC" component={SympsGCC} />
            <Route path="/csce2025/conferences-HIMS" component={SympsHIMS} />
            <Route path="/csce2025/conferences-ICAI" component={SympsICAI} />
            <Route path="/csce2025/conferences-ICDATA" component={SympsICDATA} />
            <Route path="/csce2025/conferences-ICOMP" component={SympsICOMP} />
            <Route path="/csce2025/conferences-ICWN" component={SympsICWN} />
            <Route path="/csce2025/conferences-IKE" component={SympsIKE} />
            <Route path="/csce2025/conferences-IPCV" component={SympsIPCV} />
            <Route path="/csce2025/conferences-MSV" component={SympsMSV} />
            <Route path="/csce2025/conferences-PDPTA" component={SympsPDPTA} />
            <Route path="/csce2025/conferences-SAM" component={SympsSAM} />
            <Route path="/csce2025/conferences-SERP" component={SympsSERP} />
            <Route path="/csce2025/conferences-ICEQT" component={SympsICEQT} />
            <Route path="/csce2025/conferences" component={Symps} />
            <Route path="/csce2025/demography_history" component={Demo} />
            <Route path="/csce2025/publisher" component={Pub} />
            <Route path="/csce2025/paper_submission" component={PaperS} />
            <Route path="/csce2025/paper_categories" component={PapC} />
            <Route path="/csce2025/contact" component={Contact} />
            {/* <Route path="/csce2025/topics" component={Scope} /> */}
            {/* <Route path="/csce2025/paper_review_pocess" component={Review} /> */}
            <Route path="/csce2025/deadlines" component={Deadlines} />
            {/* <Route path="/csce2025/committees" component={Org} /> */}
            <Route path="/csce2025/venue" component={Venue} />
            <Route path="/csce2025/registration" component={Reg} />
            <Route path="/csce2025/registration_start" component={Reg_start} />
            <Route path="/csce2025/registration_form" component={Reg_Form} />
            <Route path="/csce2025/registration_form_add" component={Reg_Form2} />
            <Route path="/csce2025/registration_fee_calc" component={Reg_Fee_C} />
            <Route path="/csce2025/registration_fee_calc_add" component={Reg_Fee_C2} />
            <Route path="/csce2025/registration_card" component={Reg_Card} />
            <Route path="/csce2025/registration_card_add" component={Reg_Card2} />
            <Route path="/csce2025/registration_success" component={Reg_done} />
            <Route path="/csce2025/authors" component={Authors} />
            <Route path="/csce2025/paper_category_rrp" component={PapC_6} />
            <Route path="/csce2025/paper_category_srp" component={PapC_4} />
            <Route path="/csce2025/paper_category_pst" component={PapC_2} />
            <Route path="/csce2025/presentation_modes" component={PresMs} />
            <Route path="/csce2025/career_fair" component={Jobs} />
            <Route path="/csce2025/schedules" component={Schedules} />
            <Route path="/csce2025/past_speakers" component={Speakers} />
            {/* <Route path="/csce2025/session_proposals" component={Sess_P} /> */}
            <Route path="/csce2025/special_issues" component={Spec_issue} />
            <Route path="/csce2025/keynotes" component={Keynotes} />
            {/* <Route path="/csce2025/final_papers" component={FinalPapers} /> */}
            
            <Route path="/csce2025/keynotes/murray" component={Keynote1} />
            <Route path="/csce2025/keynotes/galar" component={Keynote4} />
            <Route path="/csce2025/keynotes/deligiannidis" component={Keynote3} />
            {/* <Route path="/csce2025/keynotes/alibakhsh" component={Keynote10} /> */}
            {/* <Route path="/csce2025/keynotes/dimitrakopoulos" component={Keynote12} /> */}
            {/* <Route path="/csce2025/keynotes/amirian" component={Keynote8} />  */}

            {/* <Route path="/csce2025/keynotes/crowder" component={Keynote2} />
            <Route path="/csce2025/keynotes/tafti" component={Keynote6} />
            <Route path="/csce2025/keynotes/weiss" component={Keynote5} /> */}
            
            {/* 
            <Route path="/csce2025/keynotes/gorlatch" component={Keynote7} /> 
            
             */}
            
            {/* <Route path="/csce2025/approved_sessions" component={Approved_Sesss} /> */}
            {/* <Route path="/csce2025/approved_sessions/BTA21" component={Approved_Sesss1} />
            <Route path="/csce2025/approved_sessions/ETTICM" component={Approved_Sesss2} />
            <Route path="/csce2025/approved_sessions/AgileIT" component={Approved_Sesss3} /> */}
            <Route path="/csce2025/visa/start" component={Visa_start} />
            <Route path="/csce2025/visa/form" component={Visa_Form} />
            <Route path="/csce2025/visa/done" component={Visa_done} />
            <Route path="/csce2025/custom_payment/:url_id" component={Reg_Card_Cust} />
            <Route path="/csce2025/custom_pyment_success" component={Reg_done_cust} />
            <Route path="/csce2025/program" component={PresSch} />
            <Route path="/csce2025/gallery" component={Photos} />
            <Route path="/csce2025/proceedings" component={Proceedings} />
            <Route path="/csce2025/committees" component={Committees} />    

            {/* <Route path="/csce2025/registration_card_arabnia" component={Reg_Card_Arabnia} /> */}

            <Route path='*' component={Home} />
          </Route>
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
  , document.getElementById('react-root'));
