import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class PapC_2 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCE 2025</h1>
              <h6>The 2025 World Congress in</h6>
              <h6>Computer Science, Computer Engineering, & Applied Computing (CSCE)</h6>
              <h6>July 21-24, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Posters (PST) Category</h4>
            <div className="par">
            <h6>PRESENTATION IN DISCUSSION/POSTER SESSIONS AND 2-PAGE PUBLICATION IN THE IEEE CPS PROCEEDINGS</h6>
              <br/>
              Accepted papers in Posters (PST) category will be presented in Discussion/Poster Sessions.
              <br/><br/>
              Authors of these papers are expected to bring to the conference a maximum of 12 pages (8.5 X 11 inch) describing their paper. These pages will be pinned to Presentation Boards (48x28 inch) during the Discussion/Poster Sessions. Please use large fonts so that the paper can be read from about 1.5 meter distance - any reasonable typesetting format is acceptable. Authors of papers in this category (PST) are expected to be present during these sessions to answer any questions that readers might have. Presenters are responsible for removing their presentation materials at the end of each Discussion/Poster Session. Scheduled times for Discussion/Poster Sessions will be emailed to you.
              <br/><br/>
              Please note that you are required to upload an electronic copy of your 2-page Camera-ready Paper to the publication website before the announced deadline. The URL to the website is included in the notification of acceptance that has been emailed to the authors of the accepted papers.
              <br/><br/>
              Accepted papers in this category will be included in the conference proceedings (as Posters).
              <br/><br/>
              Your Camera-ready Paper should not exceed 2 IEEE style pages (double-column format).
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PapC_2;